.main-container {
  background: #231C3F;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 360px;
  width: 100%;
  position: relative;
}

@media (min-width: 501px) {
.main-container {
  background: #231C3F;
  min-height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  min-width: 500px;
  position: relative;
}
}

.message-container {
  background: #FFF;
  //background: #231C3F;
  display: flex;
  min-width: 320px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 15px 15px 15px;
  border-radius: 15px;
  gap: 20px;
}

@media (min-width: 501px) {
.message-container {
  background: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 400px;
  padding: 20px 15px 15px 15px;
  border-radius: 15px;
  gap: 20px;
}
}

.message-content {
  background: #FFF;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background: #231C3F;
  display: flex;
  width: 100%;
  padding: 0px 10px 0px 10px;
  gap: 10px;
}

.message-header {

font-family: Gilroy;
font-size: 18px;
font-weight: 700;
line-height: 22px;
letter-spacing: 0px;
text-align: center;
}


.message-description {

font-family: Gilroy;
font-size: 16px;
font-weight: 500;
line-height: 19px;
letter-spacing: 0px;
text-align: center;
}


.message-button {
border-radius: 15px;
background: linear-gradient(0deg, #231C3F, #231C3F);
color: #FFF;

padding: 15px 30px 12px 30px;
width: 100%;

text-decoration: none;
font-family: Gilroy;
font-size: 16px;
font-weight: 600;
line-height: 20px;
letter-spacing: 0em;
text-align: center;
}

