@import "variables";


.modal-container {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $modal-screen-container-background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100vh;

  overflow: auto;

}


.onBoarding {
  //background: #D6D8DD;
  z-index: 1;
  height: 100vh;
}
@media (min-width: 840px) {
  .onBoarding {
   // background: #231C3F;
    z-index: 1;
    height: calc(100vh - 150px);
  }
}


.changepass {
  display: flex;
  justify-content: center;
  //  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: row;
  position: absolute;
  //  left: calc(50% - 500px/2);
}
@media (min-width: 840px) {
  .changepass {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 150px);
    width: 100%;
    margin: 0;
    padding: 0;
    flex-direction: row;
    position: absolute;
    //  left: calc(50% - 500px/2);
    top: 60px;
  }
}

.changepass-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
}

.changepw-form {
  width: 100%;
  height: 100%;
  max-width: unset;
  display: flex;
  justify-content: center;
}
@media (min-width: 840px) {
  .changepw-form {
    width: 100%;
    height: unset;
    max-width: 500px;
    display: flex;
    justify-content: center;
  }
}

//settings container
.changepw-container {
  display: flex;
  min-width: 360px;
  height: auto;
  flex-grow: 1;
  width: 100%;
  gap: 25px;
  min-width: 360px;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: #F9F6F4;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
}
@media (min-width: 840px) {
  .changepw-container {
    display: flex;
    max-width: 500px;
    width: 100%;
    gap: unset;
    padding: 30px 0px;
    isolation: isolate;
    height: unset;
    flex-grow: unset;
    flex-direction: column;
    border-radius: 20px;
    align-items: flex-start;
    position: relative;

    background: #FFF;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
  }
}

//account container
.changepw-container-inner {
  display: flex;
  max-width: 600px;
  padding: 0px 10px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  //align-self: stretch;

  border-radius: 20px;
  background: #F9F6F4;
  gap: 25px;
  //box-shadow: 0px 0px 30px 0px #272041;
}
@media (min-width: 840px) {
  .changepw-container-inner {
    display: flex;
    max-width: unset;
    padding: 0px 30px;
    height: unset;
    flex-direction: column;
    flex-grow: unset;
    align-items: flex-start;
    align-self: stretch;

    //border-radius: 20px;
    gap: unset;
    background: #FFF;
    //box-shadow: 0px 0px 30px 0px #272041;
  }
}
//left button
.changepw-exit {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 20px;
  top: 19px;
}
@media (min-width: 840px) {
  .changepw-exit {
    width: 34px;
    height: 34px;
    position: absolute;
    left: 20px;
    top: 20px;
    z-index: 1;
  }
}
//inner container
.changepw-main {
  display: flex;
  padding: 25px;
  gap: 16px;
  background: #FFF;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 20px;
}
@media (min-width: 840px) {
  .changepw-main {
    display: flex;
    //padding: 0px 30px;
    padding: unset;
    gap: unset;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    background: unset;
  }
}

//header
.changepw-header-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  background: #FFF;
}
@media (min-width: 840px) {
  .changepw-header-container {
    display: flex;
    position: relative;
    padding: 0px 20px 30px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }
}
//text
.changepw-header {
  padding: 22px 0px 18px 0px; // causes upper alignment issue
  width: 100%;
  flex: 1 0;
  align-self: stretch;
  color: var(--header-on-white, #2F364B);
  text-align: center;
  font-family: Gilroy;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
@media (min-width: 840px) {
  .changepw-header {
    padding: unset;
    align-self: stretch;
   // color: var(--header-on-white, #2F364B);
    text-align: center;
    font-family: Gilroy;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
//fields
.changepw-fields-container {
  display: flex;
  //padding: 25px 25px 0px 25px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: #FFF;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
@media (min-width: 840px) {
  .changepw-fields-container {
    display: flex;
    //padding: unset;
    padding: 0px 0px 16px;
    //padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    background: #FFF;
    border-top-right-radius: unset;
    border-top-left-radius: unset;
  }
}
//input field
.changepw-fields-top {
  display: flex;
  //height: 59px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
//inner container
.changepw-fields-top-int {
  display: flex;
  max-width: 440px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

//informational
.changepw-hint {
  align-self: stretch;

  color: #5A6A7A;
  text-align: center;
  font-family: Gilroy;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
//input field
.changepw-fields-bottom {
  display: flex;
  //height: 59px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
//inner container
.changepw-fields-bottom-int {
  max-width: 440px;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}


.changepw-button-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 440px;
  padding: 0px 25px 25px 25px;;
  background: #FFF;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
@media (min-width: 840px) {
  .changepw-button-container {
    padding: unset;
    border-bottom-right-radius: unset;
    border-bottom-left-radius: unset;
  }
}

.error {
  display: flex;
  width: 100%;
  height: 48px;
  color: #CB0000;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  align-items: center;
  justify-content: center;
}
