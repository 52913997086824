@import "variables";

.modal-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 20px;

  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  //background: $modal-screen-container-background-color;
  background: rgba(0, 0, 0, 0.40);
  z-index: 999999;
  width: 100%;
  //height: 85%;
  height: 100vh;
  overflow: auto;
}
@media (min-width: 501px) {
.modal-screen-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 20px;

  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  //background: $modal-screen-container-background-color;
  background: rgba(0, 0, 0, 0.40);
  z-index: 999999;
  width: 100%;
  //height: 85%;
  height: 100%;
  overflow: auto;
}
}

.modal-container-informative {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 15px 15px;
  gap: 20px;
  width: 300px;
  max-width: 400px; /* adjust these to by dynamic */
 // width: 100%;
//  height: 172px;
  @include modal-screen-container-informative-color;
}
@media (min-width: 501px) {
.modal-container-informative {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 15px 15px;
  gap: 20px;

  max-width: 400px; /* adjust these to by dynamic */
  width: 100%;
//  height: 172px;
  @include modal-screen-container-informative-color;
}
}

.modal-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;

  //width: 270px;
  //height: 70px;
}

.modal-title {
  //width: 250px;
  height: 22px;
  color: $modal-title-color;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.modal-description {
  //width: 250px;
//  height: 38px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: $modal-description-color;
}

.action-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;

  //width: 270px;
  width: 100%;
  height: 47px;
}

.modal-button {
  gap: 10px;
  width: 100%;
  padding: 15px 30px 12px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  background: linear-gradient(0deg,#231c3f,#231c3f),linear-gradient(0deg,#535256,#535256),#505050;
  border-radius: 15px;
  box-shadow: 0 0 10px 0 rgba(65,72,96,.15);
  display: flex;
}
.modal-button-label {
  color: $enabled-button-text;
  width: 23px;
  height: 20px;
  font-weight: 600;
  text-align: center;
}
