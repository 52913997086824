* {
  overscroll-behavior: none;
}

.history {
position: fixed;
display: flex;
top: 0%;
left: 0%;
align-items: flex-start;
justify-content: center;
z-index: 99;
width: 100vw;
height: 100vh;
//background: rgba(35, 28, 63, 0.5);
}

@media (min-width: 840px) {
.history {
padding-top: 60px;
position: fixed;
overflow-y: auto;
//height: calc(100vh - 60px);
height: 100%;
display: flex;
top: 0;
left: 0;
//align-items: center;
justify-content: center;
z-index: 99;
width: 100vw;
background: rgba(35, 28, 63, 0.5);
}
}

.history-container {
position: relative;
display: flex;
height: 100%;
//max-width: 500px;
flex-direction: column;
align-items: flex-start;
background: #FFF;
width: 100%;
//overflow: hidden;
//flex-grow: 1;
//height: 100%;
}

@media (min-width: 840px) {
.history-container {
position: relative;
//height: 100vh;
//overflow-y: auto;
display: flex;
max-width: 500px;
flex-direction: column;
align-items: flex-start;
background: #FFF;
border-radius: 20px;
box-shadow: 0px 0px 30px 0px #272041;
height: unset;
}
}



.history-header {
display: flex;
position: static;
width: 100%;
padding-top: 20px;
//border-top-right-radius: 20px;
//border-top-left-radius: 20px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 14px;
align-self: stretch;
background: #FFF;

color: var(--header-on-white, #2F364B);
text-align: center;
font-size: 28px;
font-family: Gilroy;
font-style: normal;
font-weight: 700;
line-height: normal;
}
@media (min-width: 840px) {
.history-header {
display: flex;
position: unset;
width: unset;
padding-top: 20px;
border-top-right-radius: 20px;
border-top-left-radius: 20px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 14px;
align-self: stretch;
background: #FFF;

color: var(--header-on-white, #2F364B);
text-align: center;
font-size: 28px;
font-family: Gilroy;
font-style: normal;
font-weight: 700;
line-height: normal;
}
}


.history-exit-button {
position: absolute;
top: 20px;
left: 20px;
cursor: pointer;
}
@media (min-width: 840px) {
.history-exit-button {
position: absolute;
top: 20px;
left: unset;
right: 20px;
cursor: pointer;
}
}

.history-divider {
height: 1px;
align-self: stretch;
background: var(--exposed-data-value-background, #FAF6F4);
}

.history-feed-container {
display: flex;
flex-direction: column;
height: 100%;
overflow-y: auto;
width: 100%;
//margin-top: 70px;
padding: 20px 20px 20px 20px;
align-items: flex-start;
background: #F9F6F4;
}
@media (min-width: 840px) {
.history-feed-container {
width: unset;
flex-direction: unset;
overflow-y: hidden;
display: flex;
padding: 20px;
height: 100%;
//margin-top: unset;
align-items: flex-start;
background: #F9F6F4;
border-bottom-right-radius: 20px;
border-bottom-left-radius: 20px;
}
}

.history-feed {
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 10px;
align-self: stretch;
flex-grow: 1;
//min-height: calc(100vh - 110px);
}
@media (min-width: 840px) {
.history-feed {
display: flex;
max-width: 460px;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
min-height: unset;
}
}

.history-empty-container {
display: flex;
padding: 0px 40px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
flex-grow: 1;
}
@media (min-width: 840px) {
.history-empty-container {
display: flex;
padding: 0px 40px;
flex-direction: column;
justify-content: unset;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}
}

.history-empty-top {
align-self: stretch;
color: var(--header-on-white, #2F364B);
text-align: center;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.history-empty-bottom {
align-self: stretch;
color: var(--messaging-on-white, #545E75);
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
