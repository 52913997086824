@import "variables";

.confirm-row {
  height: 351px;
}

.reset-account {
  height: 100vh;

  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: unset;

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0px;


  max-width: 500px;
  width: 100%;
}

@media (min-width: 500px) {
.reset-account {
  height: 351px;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
}
}
.confirm-account {
  height: 100vh !important;

  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: unset !important;
}
@media (min-width: 500px) {
.confirm-account {
  height: 351px;

  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
}
}

.confirm-inner-container {
  height: 291px;
}

.confirm-header-text {
  margin-left: 20px;
  margin-right: 20px;
}

.confirm-input-fields {
  align-self: stretch;

}

.confirm-input-field {
  display: flex;
  align-items: center;
  align-self: stretch;
  position: relative;
}



.confirm-input-inner-container {
  width: 100%;
  flex-shrink: 0;
}

.mSpanText {
  bottom: 0px;
}

.mLabel {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.mInput {
  padding-top: 11px;
  padding-bottom: 0px;
}

.pwmask {
  width: 20px;
  height: 15px;
  flex-shrink: 0;
  cursor: pointer;
  position: absolute;
  right: 7px;
  bottom: 13px;
  z-index: 10;
  visibility: hidden;
}

.mLabel:focus-within .pwmask {
  visibility: unset;
}

.error {
  align-items: flex-start !important;
}

.pwnotice {
  color: $modal-description-color !important;
}
