@import "variables";

.confirm-row {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background-size: cover;
  background-color: #FFF;
  height: 100%;
  width: 100%;
  /*padding: 0px; */


  /* height: 407px; */
}
@media (min-width: 840px) {
.confirm-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  position: unset;
  background-size: cover;
  background-color: #FFF;
  border-radius: 20px;
  height: unset;
  /*padding: 0px; */


  /* height: 407px; */
}
}

.confirm-account {
  display: flex;
  position: static;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 59px 0px 40px 0px;
  height: 100%;


  max-width: 600px;
  width: 100%;

  background: #FFF;
}
@media (min-width: 840px) {
.confirm-account {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0px;
  height: unset !important;


  width: 500px;
  //width: 100%;

  background: #FFF;
  box-shadow: 0px 0px 30px 0px #272041 !important;
  border-radius: 20px;
}
}

.confirm-back {
  display: flex;
  position: absolute;
  width: 34px;
  height: 34px;
  left: 15px;
  top: 10px;
  padding: 0;
}
@media (min-width: 840px) {
.confirm-back {
  display: flex;
  position: absolute;
  width: 34px;
  height: 34px;
  left: 20px;
  top: 20px;
  padding: 0;
}
}

.confirm-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 35px;
  height: unset !important;
}
@media (min-width: 840px) {
.confirm-inner-container {
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 30px;
  height: unset !important;
}
}

.confirm-header {
  display: flex;
  padding: 0px 0px 30px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
@media (min-width: 840px) {
.confirm-header {
  display: flex;
  padding: 0px 20px 30px 30px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
}

.confirm-header-text {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* Header on white */
  color: $header-on-white;
  margin-left: 10px;
  margin-right: 10px;
}
.confirm-header-text-two {
  color: var(--Messaging-on-white, #5A6A7A);
  text-align: center;
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 23px; /* 127.778% */
}
.confirm-header-text-three {
  display: inline-flex;
  color: var(--Messaging-on-white, #5A6A7A);
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}
@media (min-width: 840px) {
.confirm-header-text-three {
  display: inline-flex;
  color: var(--Messaging-on-white, #5A6A7A);
  font-family: Gilroy;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}
}


.confirm-input-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px 0px;
  gap: 16px;
  width: 100%;
}
.confirm-input-field {
  width: 100%;
}

.confirm-input-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  position: relative;
  height: 59px;
}

.button-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: center;
  justify-content: center;

}

.button-disabled {
  display: flex;
  padding: 23px 40px 20px 40px;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: unset !important;
  text-align: center;
  align-items: center;

}

.button {
  display: flex;
  padding: 23px 40px 20px 40px;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: unset !important;
  text-align: center;
  align-items: center;
}


.mLabel {
  bottom: unset !important;
}

.mSpan {
  bottom: 7px !important;
}

.link {
  cursor: pointer;
  width: 100%;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
}

.confirm-bottom {
  display: flex;
  padding: 35px 35px 0px 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  width: 100%;
}
.confirm-bottom-inner-container {
  display: flex;
  padding: 0px 30px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
}

.confirm-bottom-text {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 500;
  font-size: 17px;
  width: 100%;
  /* identical to box height */
  text-align: center;
  text-decoration-line: underline;
  color: #5A6A7B;
}
