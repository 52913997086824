@import "variables";


button {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 0px 0px;
  padding: 21px 40px 22px;
  width: 100%;
  height: 64px;
  background: $enabled-button-background;
  //box-shadow: 0px 0px 10px rgba(65, 72, 96, 0.15);
  box-shadow: none;
  border: none;
  border-radius: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;

  /* identical to box height */
  text-align: center;
  color: $enabled-button-text;
}

.button-disabled {
  display: flex;
  flex-direction: column;
  margin: 0px 0px 0px 0px;
  align-items: center;
  //padding: 23px 40px 20px;
  padding: 21px 40px 22px;


  width: 100%;
  height: 64px;

  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  //box-shadow: 0px 0px 10px rgba(65, 72, 96, 0.15);
  box-shadow: none;
  border: none;
  border-radius: 15px;
  background: $disabled-button-background;
  color: rgba(255, 255, 255, 0.3);

}

.button-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: center;
  justify-content: center;
  box-shadow: none;
  border: none;
}
