.menu-dots {
  cursor: pointer;
  border-radius: 15px;
}

.kebab-menu:hover  {
  border-radius: 15px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.1);
}

.kebab-menu:hover + .header-menu-button {
  background-color: transparent;
}

.header-menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.header-menu-button:hover + .kebab-menu {
  background-color: transparent;
}

.kebab-menu {
  display: flex;
  padding: 10px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  cursor: pointer;
}
.kebab-menu .dot {
  width: 4px;
  height: 4px;
  background-color: #FFFFFF;
  flex: none;
  order: 1;
  border-radius: 50%;
  margin: 2px 0;
}

.header-menu-container {
  display: flex;
  z-index: 100;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 446px;
  position: absolute;
  width: 250px;
  height: 222px;
  right: 5px;
  top: 55px;
  background: #2C2744;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 15px;
}

.menu-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 250px;
  height: 222px;
}

.menu-item {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* Header on dark */
  color: #ECECEC;
}

.buttons-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  width: 250px;
  height: 140px;
}

.header-menu-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 20px;
  border-radius: 15px;
  width: 230px;
  height: 60px;
}



.icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1px 0px;
  width: 20px;
  height: 60px;
}

.icon-settings {
  width: 20px;
  height: 21.3px;
}

.icon-history {
  width: 20px;
  height: 18px;
}

.menu-button {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 20px;
  width: 230px;
  height: 60px;
  border-radius: 15px;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.label-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 1px 14px 0px;
}

.label-text {
  width: 142px;
  height: 57px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  /* Header on dark */
  color: #ECECEC;
  text-decoration: none;
}

.button-label-text {
  width: 142px;
  height: 57px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.0;
  display: flex;
  align-items: center;
  /* Header on dark */
  color: #ECECEC;
  text-decoration: none;
  background: transparent;
  border: 0;
}

.header-menu-divider {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 250px;
  height: 2px;
  border-radius: 15px;
  background: rgba(255, 255, 255, 0.1);
}

.menu-logout {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 20px;
  width: 230px;
  height: 60px;
  color: transparent;
}

.menu-logout:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}

.mobile-kebab-container {
  top: 0px;
  left: 0px;
  gap: 10px;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  z-index: 98;
  background: #FFF;
}

.mobile-kebab-navigation {
  display: flex;
  position: relative;
  padding: 12px 20px 18px 20px;
  justify-content: flex-end;
  align-items: flex-start;
  align-self: stretch;
}

.mobile-kebab-caret-container {
  display: flex;
}

.mobile-kebab-button-container {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  gap: 10px;
}

.mobile-kebab-row-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}

.mobile-kebab-row {
  display: flex;
  height: 60px;
  padding: 0px 20px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 15px;
  background: #F9F6F4;
}

.mobile-kebab-row-icon-container {
  display: flex;
  padding: 1px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.mobile-kebab-row-icon {
  width: 24px;
  height: 24px;
}

.mobile-kebab-row-text-container {
  display: flex;
  padding: 3px 14px 0px 14px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
}

.mobile-kebab-row-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  color: var(--header-on-white, #2F364B);
  font-family: Gilroy;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mobile-kebab-row-right-container {
  display: flex;
  padding: 1px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

/*# sourceMappingURL=KebabMenu.module.css.map */
