.ph {
overflow: hidden;
display: flex;
position: relative;
flex-direction: column;
align-items: flex-start;
border-radius: 20px;
background: #F9F6F4;
box-shadow: 0px 0px 30px 0px #272041;
}
//header
.ph-header-container {
display: flex;
padding: 20px 0px 19px 0px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 14px;
align-self: stretch;
background: #FFF;
}
//title container
.ph-header {
display: flex;
align-items: flex-start;
}
//text
.ph-header-text {
color: var(--header-on-white, #2F364B);
text-align: center;
font-family: Gilroy;
font-size: 28px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

//inner container
.ph-main-container {
display: flex;
width: 700px;
padding: 0px 30px;
align-items: flex-start;
}
//content container
.ph-main {
display: flex;
padding: 30px 0px;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
}
//payments container
.ph-main-int {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
}
//header container
.ph-column-header-container {
display: flex;
padding: 0px 25px 2px 25px;
flex-direction: column;
align-items: flex-start;
gap: 20px;
}
//inner container
.ph-column-header {
display: flex;
width: 590px;
height: 19px;
justify-content: center;
align-items: flex-start;
gap: 10px;
}
//invoice
.ph-invoice {
display: flex;
width: 65px;
flex-direction: column;
flex-shrink: 0;

color: #000;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 19px;
}
//total
.ph-total {
display: flex;
width: 140px;
flex-direction: column;
flex-shrink: 0;

color: #000;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 19px;
}
//date
.ph-date {
display: flex;
width: 140px;
flex-direction: column;
flex-shrink: 0;

color: #000;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 19px;
}
//product
.ph-product {
display: flex;
width: 215px;
flex-direction: column;
flex-shrink: 0;

color: #000;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 19px;
}

//payment container
.ph-row-container {
display: flex;
padding: 0px 25px;
flex-direction: column;
align-items: flex-start;
gap: 20px;
border-radius: 15px;
background: #FFF;
}
//inner container
.ph-row {
display: flex;
width: 590px;
height: 60px;
align-items: flex-start;
gap: 10px;
}
//invoice container
.ph-row-invoice {
display: flex;
padding: 4px 14px 0px 0px;
align-items: center;
align-self: stretch;
}
//label
.ph-row-invoice-lable {
color: var(--messaging-on-white, #545E75);
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
//total container
.ph-row-total{
display: flex;
width: 140px;
padding: 4px 14px 0px 0px;
align-items: center;
align-self: stretch;
}
//label
.ph-row-total-label {
color: #000;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
//date container
.ph-row-date {
display: flex;
width: 140px;
padding: 4px 14px 0px 0px;
align-items: center;
align-self: stretch;
}
//label
.ph-row-date-label {
color: #000;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
//product container
.ph-row-product {
display: flex;
width: 215px;
padding: 4px 14px 0px 0px;
align-items: center;
flex-shrink: 0;
align-self: stretch;
}
//label
.ph-row-product-label {
color: #000;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

//left button
.ph-exit {
width: 34px;
height: 34px;
position: absolute;
left: 20px;
top: 20px;
}
