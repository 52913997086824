li::marker {
  content: "";
 }

.breach-feed-cards-container {
display: flex;
flex-direction: column;
max-width: 650px;
gap: 10px;
width: 100%;
}
@media (min-width: 840px) {
.breach-feed-cards-container {
display: flex;
flex-direction: column;
gap: 10px;
width: 100%;
}
}

.breach-feed-cards-date-container {
display: flex;
padding: 20px 30px 10px;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}

.breach-feed-cards-date {
display: flex;
flex-direction: column;
flex: 1 0 0;

color: #545E75;
text-align: center;
font-size: 17px;
font-family: Gilroy;
font-style: normal;
font-weight: 700;
line-height: 19px;
}

.breach-feed-card {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 20px;
background: #FFFFFF;
border-radius: 20px;
}

.breach-feed-card-header {
display: flex;
flex-direction: row;
max-width: 440px;
width: 100%;
align-items: center;
padding: 0px 0px 15px;
gap: 10px;
}

.breach-feed-card-icon {
width: 40px;
height: 40px;
border-radius: 20px;
}

.breach-feed-card-title-container {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 3px 0px 0px;
}

.breach-feed-card-title {
display: flex;
font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 27px;
/* Header on white */
color: #2F364B;
}

.breach-feed-card-description {
display: flex;
flex-direction: row;
align-items: center;
padding: 0px 0px 20px;

font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 23px;

color: #545E75;
}

.breach-feed-card-datalist {
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 9px;
}

.breach-feed-card-row {
//height: 37px;
width: 100%;
display: flex;
justify-content: center;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}

.breach-feed-card-row-left-container {
display: flex;
//width: 208px;
justify-content: center;
align-items: center;
align-self: stretch;
flex: 0 0 calc(40% - 10px);
}
.breach-feed-card-row-left {
//width: 208px;
display: flex;
flex-direction: row;
align-items: center;
padding: 12px 0px 9px;
flex: 1 0;
}
.breach-feed-card-row-left-unknown {
//width: 208px;
display: flex;
flex-direction: row;
align-items: center;
padding: 7px 15px 5px 15px;
border-radius: 12px;
flex: 1 0;
background: var(--exposed-data-value-background, #FAF6F4);
}

.breach-feed-card-row-left-internal {
display: flex;
overflow-wrap: break-word;
white-space: normal;
word-break: break-word;
flex-direction: column;
justify-content: center;
align-self: stretch;
//height: 25px;
flex: 1 0 0;

color: var(--messaging-on-white, #545E75);
font-size: 15px;
font-family: Gilroy;
font-style: normal;
font-weight: 600;
line-height: 19px;
}

.breach-feed-card-row-left-internal-unknown {
display: flex;
overflow-wrap: break-word;
white-space: normal;
word-break: break-word;
//height: 25px;
flex-direction: column;
justify-content: center;

color: var(--exposed-data-value, #2F364B);
font-size: 15px;
font-family: Gilroy;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.breach-feed-card-row-right-container {
display: flex;
//width: 209px;
flex: 0 0 calc(60% - 10px);
justify-content: center;
align-items: center;
}


.breach-feed-card-row-right {
//width: 209px;
display: flex;
padding: 15px;
flex-direction: row;
flex: 1 0;
justify-content: flex-end;
align-items: center;
border-radius: 12px;
background: var(--exposed-data-value-background, #FAF6F4);
}

.breach-feed-card-row-right-unknown {
//width: 209px;
display: flex;
//padding: 7px 15px 5px 15px;
padding: 15px;
flex-direction: row;
flex: 1 0;
justify-content: flex-start;
align-items: center;
border-radius: 12px;
background: var(--exposed-data-value-background, #FAF6F4);
}

.breach-feed-card-row-right-internal {
display: flex;
gap: 5px;
overflow-wrap: break-word;
white-space: normal;
word-break: break-word;
//height: 25px;
flex-direction: column;
justify-content: center;
align-self: stretch;

color: var(--exposed-data-value, #2F364B);
text-align: right;
font-size: 15px;
font-family: Gilroy;
font-style: normal;
font-weight: 600;
line-height: normal;
}


.breach-feed-card-row-right-internal-unknown {
display: flex;
overflow-wrap: break-word;
white-space: normal;
word-break: break-word;
//height: 25px;
flex-direction: column;
justify-content: center;
align-self: stretch;

color: var(--exposed-data-value, #2F364B);
text-align: left;
font-size: 15px;
font-family: Gilroy;
font-style: normal;
font-weight: 600;
line-height: normal;
}
