@import "variables";

@font-face {
  font-family: 'Gilroy';
  src: url('https://img.mirkat.com/web/fonts/Gilroy-Regular.otf') format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://img.mirkat.com/web/fonts/Gilroy-Medium.otf') format('opentype');
  font-style: normal;
  font-weight: 500;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://img.mirkat.com/web/fonts/Gilroy-Semibold.otf') format('opentype');
  font-style: normal;
  font-weight: 600;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://img.mirkat.com/web/fonts/Gilroy-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 700;
  font-display: block;
}

@font-face {
  font-family: 'Gilroy';
  src: url('https://img.mirkat.com/web/fonts/Gilroy-Bold.otf') format('opentype');
  font-style: normal;
  font-weight: 800;
  font-display: block;
}

.noSelect, a, img, button, .dismiss-button {
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera, and Edge */
}

//Block safari from loading inline icon - causes a text shift
input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $primary-color !important;
  font-size: 20px !important;
  font-family: $font-family !important;
}

::selection {
  background: lightgray; /* remove the default light blue text selection */
}


* {
  font-family: $font-family;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: $primary-color;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

button {
  cursor: pointer;
}

body {
  background: $background-color;
}

[class*="col-"] {
  width: 100%;
}

/* Small devices (portrait phones, less than 500px) */
@media only screen and (min-width: 500px) {
  .col-1 {max-width: 8.33%;}
  .col-2 {max-width: 16.66%;}
  .col-3 {max-width: 25%;}
  .col-4 {max-width: 33.33%;}
  .col-5 {max-width: 41.66%;}
  .col-6 {max-width: 50%;}
  .col-7 {max-width: 58.33%;}
  .col-8 {max-width: 66.66%;}
  .col-9 {max-width: 75%;}
  .col-10 {max-width: 83.33%;}
  .col-11 {max-width: 91.66%;}
  .col-12 {max-width: 100%;}
}

/* Medium devices (tablets, 600px and up) */
@media only screen and (min-width: 768px) {
  .col-1 {max-width: 8.33%;}
  .col-2 {max-width: 16.66%;}
  .col-3 {max-width: 25%;}
  .col-4 {max-width: 33.33%;}
  .col-5 {max-width: 41.66%;}
  .col-6 {max-width: 50%;}
  .col-7 {max-width: 58.33%;}
  .col-8 {max-width: 66.66%;}
  .col-9 {max-width: 75%;}
  .col-10 {max-width: 83.33%;}
  .col-11 {max-width: 91.66%;}
  .col-12 {max-width: 100%;}
}

/* Large devices (desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .col-1 {max-width: 8.33%;}
  .col-2 {max-width: 16.66%;}
  .col-3 {max-width: 25%;}
  .col-4 {max-width: 33.33%;}
  .col-5 {max-width: 41.66%;}
  .col-6 {max-width: 50%;}
  .col-7 {max-width: 58.33%;}
  .col-8 {max-width: 66.66%;}
  .col-9 {max-width: 75%;}
  .col-10 {max-width: 83.33%;}
  .col-11 {max-width: 91.66%;}
  .col-12 {max-width: 100%;}
}

/* Extra large devices (large desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .col-1 {max-width: 8.33%;}
  .col-2 {max-width: 16.66%;}
  .col-3 {max-width: 25%;}
  .col-4 {max-width: 33.33%;}
  .col-5 {max-width: 41.66%;}
  .col-6 {max-width: 50%;}
  .col-7 {max-width: 58.33%;}
  .col-8 {max-width: 66.66%;}
  .col-9 {max-width: 75%;}
  .col-10 {max-width: 83.33%;}
  .col-11 {max-width: 91.66%;}
  .col-12 {max-width: 100%;}
}

/* Grid Container */
.grid-container {
  display: flex;
  flex-wrap: wrap;
}



.link {
  width: 230px;
  height: 21px;
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;

  /* identical to box height */
  text-align: center;
  text-decoration-line: underline;

  color: #5A6A7B;
}


.App {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  //overflow-x: auto;
  //max-width: 100%;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.display-block {
  display: block;
  background: rgba(0, 0, 0, 0.6);
}

.display-none {
  display: none;
}

.modal-main {
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  z-index: 1050;
  overflow: auto;
}

.modal-main button {
  display: block;
  margin-top: 2rem;
}
