.upgrade-page {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 840px) {
.upgrade-page {
  display: flex;
  position: unset;
  top: unset;
  left: unset;
  height: unset;
  width: unset;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0px 0px 0px;
}
}
