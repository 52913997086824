.onboardingcard {
  padding: 30px 0px;
  isolation: isolate;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
  min-width: 360px;
  width: 100%;
  max-width: 500px;
  height: 80%;
  box-sizing: border-box;
}

.email-main {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: absolute;
  left: calc(50% - 500px/2);
  top: 60px;
}

.email-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

  max-width: 500px;
  width: 100%;
  height: 426px;
}

.email-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 40px;
}
@media (min-width: 840px) {
.email-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;
}
}

.email-container {
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;
align-self: stretch;
}
.email-top-container {
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
max-width: 600px;
}
@media (min-width: 840px) {
.email-top-container {
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
max-width: 500px;
}
}

.login-container-navigation {
display: flex;
position: relative;
padding: 0px 15px 15px 15px;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
}
@media (min-width: 840px) {
.login-container-navigation {
display: none;
}
}
.login-exit {
display: none;
}
@media (min-width: 840px) {
.login-exit {
position: absolute;
display: flex;
left: 20px;
top: 20px;
width: 34px;
height: 34px;
}
}


.email-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.email-form-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 30px;
  gap: 20px;
}

.email-form-header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;

  /* Header on white */
  color: #2F364B;
}

.email-input-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  gap: 16px;
  width: 100%;

}

.mLabel {
  margin: 0px;
}

.error {
  display: flex;
  max-width: 440px;
  width: 100%;
  height: 36px;
  color: #CB0000;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  justify-content: center;
  align-items: center;
}

.button-disabled {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 0px 0px;
  max-width: 440px;
  width: 100%;
  height: 64px;
}

.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 0px 0px;
  max-width: 440px;
  width: 100%;
  height: 64px;
}

.button-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;

  max-width: 370px;
  width: 100%;
  height: 21px;
}

.button-text {
  max-width: 310px;
  width: 100%;
  height: 21px;

  /* identical to box height */
  text-align: center;
  text-decoration-line: underline;

  color: #5A6A7B;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 35px 0px;
//  gap: 30px;
  //max-width: 440px;
  width: 100%;
  max-width: 600px;
  //height: 75px;
}
@media (min-width: 840px) {
.bottom {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 35px 35px 0px;
  gap: 30px;

  max-width: 440px;
  width: 100%;
}
}

.bottom-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 10px;
  gap: 20px;
  width: 100%;
}
@media (min-width: 840px) {
.bottom-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;

  max-width: 370px;
  width: 100%;
  height: 40px;
}
}

.bottom-text {
  width: 100%;

  color: #2F364B;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
.bottom-text-mobile {
  width: 100%;

  color: #5A6A7B;
  text-align: center;
  font-family: Gilroy;
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
}
@media (min-width: 840px) {
.bottom-text-mobile {
  display: none;
}
}

.a {
  color: #5A6A7B;
  font-family: Gilroy;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  //background: linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%);
  //color: transparent;
  //background-clip: text;
  //-webkit-background-clip: text;
}
