$nav-background-color: #333;
$nav-text-color: #fff;
$nav-height: 60px;
$nav-max-width: 1200px;
$nav-padding: 0 20px;

$footer-background-color: #333;
$footer-text-color: #fff;
$footer-height: 60px;
$footer-max-width: 1200px;
$footer-padding: 0 20px;

$enabled-button-background: linear-gradient(0deg, #231C3F, #231C3F), linear-gradient(0deg, #535256, #535256), #505050;
$enabled-button-text: #ffff;
$disabled-button-background: rgba(35, 28, 63, 0.75);
$disabled-button-text: rgba(255, 255, 255, 0.3);



$error-color: #CB0000;

$font-family: Gilroy, gilroy-light,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
$font-style: normal;

$text-color: #2F364B;

$primary-color: #241e3f;
$background-color: #231C3F;


$unclicked-link-color: #5A6A7B;


$input-focus-text-color: linear-gradient(90deg, #FF553C 2.94%, #FF960B 100%);
$input-filled-text-color: #D1D0D4;
$card-background-color: #FFFFFF;
$main-background-color: #231C3F;

/* Color Refs */
$dark-web-monitoring: linear-gradient(0deg, #09FFFF, #09FFFF), #F9F6F4;
$risk: linear-gradient(0deg, #FF5154, #FF5154), #FFFFFF;
$mirkat-theme: linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%);
$safe: #00E8AA;
$header-on-white: #2F364B;
$messaging-on-white: #545E75;
$exposed-data-value: #2F364B;
$exposed-data-value-background: #FAF6F4;
$style-2: #FAF6F4;
$navigation-chevron: #2F364B;
$input-value-on-white: #2F364B;
$logo-background: linear-gradient(145.63deg, #FF5A00 18.15%, #FFC100 92.52%);
$dark-background: #231C3F;
$header-on-dark: #ECECEC;
$message-on-dark: #ECECEC;
$message-on-white: #545E75;

$modal-title-color: #1B122C;
$modal-description-color: #5A6A7A;


@mixin icon-shadow {
  background: #C4C4C4;
  box-shadow: 0px 8px 12px rgba(96, 111, 150, 0.2);
}

@mixin card-drop-shadow-last-cards {
  background: #C4C4C4;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.06);
}

@mixin card-drop-shadow {
  background: #C4C4C4;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.18);
}

@mixin modal-screen-container-informative-color {
  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 15px;
}

$modal-screen-container-background-color: rgba(35, 28, 63, 0.5);
