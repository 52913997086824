@import "variables";

/* we can reuse this piece when circling back for cleanup - modals only need this one for the overlay */

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $modal-screen-container-background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100%;

  overflow: auto;
}
@media (min-width: 840px) {
.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $modal-screen-container-background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100vh;

  overflow: auto;
}
}

.modal-confirm-email-container {
  position: absolute;
  display: flex;
  //top: 25%;
  //max-width: 500px;
  width: 100%;
  height: 100%;
  padding: 71px 0px 40px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  //border-radius: 20px;
  //border-top-right-radius: 20px;
  //border-top-left-radius: 20px;
  box-shadow: 0px 0px 30px 0px #272041;
  background: $card-background-color;
}
@media (min-width: 840px) {
.modal-confirm-email-container {
  position: relative;
  display: unset;
  //top: 25%;
  max-width: 500px;
  min-width: unset;
  width: 100%;
  height: unset;
  padding: 30px 0px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  border-radius-top-right: unset;
  border-radius-top-left: unset;
  box-shadow: 0px 0px 30px 0px #272041;
  background: $card-background-color;
}
}

.confirm-email-header {
display: flex;
position: fixed;
flex-direction: column;
width: 100vw;
background: transparent;
height: 71px;
}
@media (min-width: 840px) {
.confirm-email-header {
display: flex;
position: unset;
flex-direction: column;
align-self: stretch;
width: unset;
background: unset;
height: unset;
}
}
.back-button {
  position: absolute;
  width: 34px;
  height: 34px;
  left: 22px;
  top: 22px;
  padding: 0;
}
@media (min-width: 840px) {
.back-button {
  position: absolute;
  width: 34px;
  height: 34px;
  left: 20px;
  top: 20px;
  padding: 0;
}
}

.modal-confirm-email-form {
  display: flex;
  max-width: 600px;
  min-width: 360px;
  flex-direction: column;
  //height: calc(100vh - 111px);
  height: 100%;
  width: 100%;
}
@media (min-width: 840px) {
.modal-confirm-email-form {
  display: flex;
  max-width: 500px;
  min-width: 360px;
  flex-direction: column;
  //height: calc(100vh - 111px);
  height: 100%;
  width: unset;
}
}

.confirm-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 35px;
  //height: auto;
}
@media (min-width: 840px) {
.confirm-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  padding: 0px 30px;
  height: auto;
}
}
.confirm-header {
  display: flex;
  padding: unset;
  flex-direction: column;
  align-items: flex-start;
  gap: unset;
  align-self: stretch;
}
@media (min-width: 840px) {
.confirm-header {
  display: flex;
  padding: 0px 20px 30px 20px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
}

.confirm-header-text {
  display: flex;
  word-break: break-word;
  overflow-wrap: break-word;
  flex-direction: column;
  align-self: stretch;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* Header on white */
  color: $header-on-white;
  margin-left: unset;
  margin-right: unset;
}
@media (min-width: 840px) {
.confirm-header-text {
  display: flex;
  word-wrap: break-word;
  flex-direction: column;
  align-self: stretch;
  font-weight: 700;
  font-size: 28px;
  text-align: center;
  align-items: center;
  justify-content: center;
  /* Header on white */
  color: $header-on-white;
  margin-left: 10px;
  margin-right: 10px;
}
}


.confirm-input-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px 0px;
  gap: 16px;
  width: 100%;
}
.confirm-input-field {
  width: 100%;
}

.confirm-input-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  position: relative;
  height: 59px;
}

.button-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: center;
  justify-content: center;

}

.button-disabled {
  display: flex;
  padding: 23px 40px 20px 40px;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: unset !important;
  text-align: center;
  align-items: center;

}

.button {
  display: flex;
  padding: 23px 40px 20px 40px;
  gap: 10px;
  flex: 1 0 0;
  align-self: stretch;
  height: unset !important;
  text-align: center;
  align-items: center;
}


.mLabel {
  bottom: unset !important;
}

.mSpan {
  bottom: 7px !important;
}

.link {
  cursor: pointer;
  width: 100%;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: #cb0000;
  font-size: 15px;
  font-weight: 500;
  height: 36px;
  text-align: center;
  line-height: 18px;
  padding: 0px 30px;
}

.confirm-bottom {
  display: flex;
  padding: 35px 35px 0px 35px;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  align-items: flex-start;
  gap: 30px;
  align-self: stretch;
  width: 100%;
}
.confirm-bottom-inner-container {
  display: flex;
  padding: 0px 30px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  width: 100%;
}

.confirm-bottom-text {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  font-weight: 600;
  font-size: 17px;
  width: 100%;
  /* identical to box height */
  text-align: center;
  text-decoration-line: underline;
  color: #5A6A7B;
}

.button-div {
  width: 100%;
  padding: 21px 30px 0px 30px;
  justify-content: center;
  align-items: center;
}
