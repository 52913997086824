@import "variables";

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: var(--Dark-Background, #231C3F);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.15);
}
.header-container {
  display: flex;
  height: 60px;
 // min-width: 830px;
  //max-width: 1280px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}
.inner-container {
  display: flex;
  //flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
}

.logo-container {
  display: flex;
  //flex-direction: row;
  align-items: center;
  //padding: 10px 0px;
  padding: 10px 0px 10px 20px;
  gap: 30px;

  //width: 133.21px;
  height: 60px;

}

.group-12549 {
  width: 133.21px;
  height: 47.5px;
}

.header-row-container {
  display: flex;
  height: 60px;
  padding: 10px 0px 10px 20px;
  align-items: center;
  gap: 30px;
}

.menu-logo {
  left: 0.1%;
  right: 68.31%;
  top: 10.42%;
  bottom: 16.25%;
  width: 133.211px;
  height: 47.5px;
}

.menu-logo2 {
  position: absolute;
  width: 40.6px;
  height: 40.97px;
  left: calc(50% - 40.6px/2 - 46.31px);
  top: 9.27px;

  background: rgba(255, 255, 255, 0.08);
}

.wordmark {
  position: absolute;
  width: 81px;
  height: 22px;
  top: 19px;
  margin-left: 10px;
}

.account-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 5px;
  height: 60px;
  gap: 30px;

  border-radius: 10px;
}
.account-inner-container {
  display: flex;
  padding: 10px 0px 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  align-self: stretch;
  border-radius: 15px;
}

.account-label-container {
  display: flex;
  padding-top: 1px;
  align-items: flex-start;
}

.account-label-text {
  color: #FFF;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  padding-right: 20px;
}

.account-label-user {
  color: #FFF;
  font-family: Gilroy;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.mobile-menu-dots {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
}
.mobile-menu-dots:active,
.mobile-menu-dots:focus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
 outline: none;
 -webkit-tap-highlight-color: rgba(0,0,0,0);
 -webkit-touch-callout: none;
}

.menu-dots {
  cursor: pointer;
  /* padding: 0px 15px 0px 30px; */
}

.menu-dots:hover {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
}


.header-link-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
}

.divider {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 61px;
  height: 1px;
  background: rgba(255, 255, 255, 0.10);
  z-index: 1;
}

.link-style-override {
  text-decoration: none;
  font-weight: 600;
  color: #FFFFFF;
}

.link-style-override:hover {
  text-decoration: none;
  color: #FFFFFF;
}

.history-popover {
position: absolute;
display: flex;
width: 500px;
left: calc(50% - 500px/2);
top: 100%;
z-index: 1;
background: rgba(35, 28, 63, 0.5);
}
