.onboardingcard {
  display: flex;
  padding: 30px 0px;
  //isolation: isolate;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  //border-radius: 20px;
  min-width: 360px;
  width: 100%;
  max-width: 500px;
  //height: 80%;
  box-sizing: border-box;
}
@media (min-width: 501px) {
.onboardingcard {
  display: flex;
  padding: 30px 0px;
  //isolation: isolate;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: unset;
  min-width: 360px;
  width: 100vw;
  max-width: unset;
  //height: 80%;
  box-sizing: border-box;
}
}
@media (min-width: 840px) {
.onboardingcard {
  display: flex;
  padding: 30px 0px;
  //isolation: isolate;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
  min-width: 360px;
  width: 100%;
  max-width: 500px;
  //height: 80%;
  box-sizing: border-box;
}
}
