.breaches {
display: flex;
position: fixed;
flex-direction: column;
align-items: center;
width: 100%;
height: 100%;
overflow-y: hidden;
background: transparent;
box-shadow: 0px 0px 30px 0px #272041;
}
@media (min-width: 840px) {
.breaches {
//position: absolute;
display: none;
height: auto;
display: flex;
flex-direction: column;
align-items: center;
width: auto;
overflow-y: unset;
//top: 0px;
//left: calc(50% - 1280px/2);
//width: 1280px;
background: #231C3F;
box-shadow: 0px 0px 30px 0px #272041;
}
}

.breaches-container {
display: flex;
position: fixed;
overflow-y: hidden;
flex-direction: column;
padding: 0px;
width: 100%;
min-width: 360px;
max-width: 839px;
background: #FFFFFF;
box-shadow: 0px 0px 30px #272041;
height: 100%;
//margin-bottom: env(safe-area-inset-bottom);
}
@media (min-width: 840px) {
.breaches-container {
display: flex;
position: relative;
flex-direction: column;
padding: 0px;
width: 100%;
height: auto;
overflow-y: unset;
max-width: 500px;
background: #FFFFFF;
//box-shadow: 0px 0px 30px #272041;
box-shadow: 0px 0px 30px 0px #272041;
border-radius: 20px;
}
}
.breaches-header-container {
display: flex;
position: static;
flex-direction: column;
width: 100vw;
background: #FFF;
}
@media (min-width: 840px) {
.breaches-header-container {
display: flex;
position: unset;
flex-direction: column;
align-self: stretch;
width: unset;
background: unset;
}
}
.breaches-header {
display: flex;
flex-direction: row;
justify-content: center;
align-items: flex-start;
align-self: stretch;
padding: 22px 22px 18px;
}

.breaches-icon {
  width: 60px;
  height: 60px;
  background-color: #797979;
  border-radius: 50%; /* This makes the background round */
  display: flex;
  justify-content: center;
  align-items: center;
  //padding-left: 8px;
  //padding-top: 10px;
}
.breaches-icon-svg {
  width: 100%;
  height: auto;
}

.breaches-caret {
position: absolute;
right: 22px;
width: 34px;
height: 34px;
}
@media (min-width: 840px) {
.breaches-caret {
display: none;
}
}
.breaches-exit-button {
display: none;
}
@media (min-width: 840px) {
.breaches-exit-button {
display: flex;
position: absolute;
width: 34px;
height: 34px;
right: 20px;
top: 20px;
cursor: pointer;
}
}

.breaches-feed-outer-container {
display: flex;
flex-direction: column;
height: 100%;
overflow-y: auto;
}
.breaches-feed-container {
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
//padding: 0px;
}
@media (min-width: 840px) {
.breaches-feed-container {
width: 100%;
display: flex;
padding-top: unset;
flex-direction: column;
align-items: flex-start;
//padding: 0px;
}
}

.breaches-feed-description-container {
display: flex;
flex-direction: row;
max-width: 650px;
align-items: flex-start;
padding: 0px 35px 35px;
}
@media (min-width: 840px) {
.breaches-feed-description-container {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px 35px 35px;
}
}

.breaches-feed-description {
white-space: pre-line;
font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 17px;
line-height: 23px;

color: #545E75;
}

.breaches-feed-breaches {
display: flex;
width: 100%;
flex-direction: column;
align-items: center;
padding: 10px 10px 30px 10px;
gap: 10px;
background: #F9F6F4;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
}
@media (min-width: 840px) {
.breaches-feed-breaches {
display: flex;
width: 100%;
flex-direction: column;
align-items: flex-start;
padding: 10px 10px 30px 10px;
gap: 10px;
background: #F9F6F4;
border-bottom-left-radius: 20px;
border-bottom-right-radius: 20px;
}
}
.breaches-feed-internal {
display: flex;
width: 100%;
gap: 10px;
}
