.page {
display: flex;
justify-content: center;
align-items: start;
}

.outer-container {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
height: 100%;
padding: 0px 20px 20px 20px;
}
@media (min-width: 840px) {
.outer-container {
display: flex;
flex-direction: column;
align-items: unset;
width: 650px;
height: unset;
padding: 50px 0px 35px 0px;
}
}
.inner-container {
display: flex;
flex-direction: column;
gap: 30px;
}
@media (min-width: 840px) {
.inner-container {
display: flex;
flex-direction: unset;
justify-content: center;
gap: 30px;
}
}
.header-container {
display: flex;
padding: 22px 40px 25px 40px;
max-width: 428px;
min-width: 360px;
flex-direction: column;
align-items: center;
gap: 15px;
}
@media (min-width: 840px) {
.header-container {
display: flex;
max-width: unset;
min-width: unset;
padding: 0px 40px;
flex-direction: column;
align-items: center;
gap: 10px;
align-self: stretch;
padding-bottom: 40px;
}
}
.header-one {
display: flex;
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.header-two {
display: flex;
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: 26px; /* 130% */
}


.free-container {
display: flex;
width: 310px;
height: 471px;
flex-direction: column;
padding: 30px; 
border-radius: 20px;
background: #FFF;
}

.free-header {
display: flex;
height: 42px;
padding-bottom: 20px;
justify-content: center;
align-items: flex-start;
gap: 42px;
align-self: stretch;

color: var(--Header-on-white, #2F364B);
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 1.0;
}

.free-spacer {
height: 42px;
}

.free-button-container {
display: flex;
padding-bottom: 30px;
flex-direction: column;
align-items: center;
gap: 10px;
align-self: stretch;
}

.free-button {
display: flex;
height: 60px;
//padding: 15px 40px 12px 40px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 15px;
background: linear-gradient(0deg, #231C3F 0%, #231C3F 100%), linear-gradient(0deg, #535256 0%, #535256 100%), #505050;
box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
text-decoration: none;
}

.free-button-text {
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 1.25;
}

.free-monitored-container {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 15px;
align-self: stretch;
}

.monitored-header {
color: var(--Header-on-white, #2F364B);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 1.0;
}


.monitored-item {
display: flex;
align-items: center;
gap: 10px;
height: 16px;
}
.monitored-icon {
display: flex;
display: flex;
width: 20px;
height: 16px;
justify-content: center;
align-items: center;
}
.monitored-item-text {
display: flex;
color: var(--Header-on-white, #2F364B);
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 12px;
padding: 3px 0px 0px 0px;
}

.paid-container {
display: flex;
width: 310px;
height: 471px;
padding: 30px;
flex-direction: column;
align-items: center;
align-self: stretch;
border-radius: 20px;
background: #FFF;
}

.paid-header-one {
display: flex;
height: 42px;
padding-bottom: 20px;
justify-content: center;
align-items: flex-start;
gap: 42px;
align-self: stretch;

text-align: center;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: 1.0;

background: var(--mirkat-theme, linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.paid-header-two {
display: flex;
height: 42px;
padding-bottom: 20px;
flex-direction: column;
align-items: center;
gap: 4px;
align-self: stretch;

color: var(--Header-on-white, #2F364B);
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 22px;
}

.paid-button-container {
display: flex;
padding-bottom: 30px;
flex-direction: column;
align-items: center;
gap: 10px;
align-self: stretch;
}

.paid-button {
display: flex;
height: 60px;
//padding: 15px 40px 12px 40px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 15px;
background: var(--mirkat-theme, linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%));
box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
text-decoration: none;
}
.paid-button-text {
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 1.25;
}


.paid-monitored-container {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 15px;
align-self: stretch;
}
.paid-monitored-items {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
}

.paid-monitored-item {
display: flex;
align-items: center;
gap: 10px;

color: var(--Header-on-white, #2F364B);
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

