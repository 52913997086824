@import "variables";


.body {
  background: #231C3F;
  height: calc(100vh - 130px);
}

.login-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
}

.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  margin: 0;
  padding: 0;
  flex-direction: row;
  position: absolute;
  left: calc(50% - 250px);
  top: 60px;
}

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 40px;
  isolation: isolate;
  max-width: 839px;
  width: 100vw;
  height: 100vh;
  //height: 447px;
  background: #FFFFFF;
}
@media (min-width: 840px) {
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: unset;
  align-items: flex-start;
  padding: 30px 0px;
  isolation: isolate;
  width: 500px;
  height: unset;
  max-width: 500px;
  //height: 447px;
  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
}
}

.login-container-navigation {
display: flex;
position: relative;
padding: 0px 15px 49px 15px;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
}
@media (min-width: 840px) {
.login-container-navigation {
display: none;
}
}
.login-exit {
width: 34px;
height: 34px;
}

@media (min-width: 840px) {
.login-exit {
display: none;
}
}

.login-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 35px;
  width: 100%;
  max-width: 600px;
  //height: 387px;
}
@media (min-width: 840px) {
.login-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;
  width: 100%;
  max-width: 500px;
  //height: 387px;
}
}
.login-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 30px 20px;
  gap: 20px;
  width: 100%;
}
.login-header-text {
  width: 100%;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  color: #2F364B;
}
.login-fields-container {
  display: flex;
  flex-direction: column;
  ///align-items: flex-start;
  padding: 0px 0px 16px;
  align-self: stretch;
  gap: 16px;
  width: 100%;
  height: 202px;
}


.login-fields-inner-container:hover:not(.Mui-disabled, .Mui-error) {
  border-bottom: none;
  box-sizing: border-box;
}

.login-fields-inner-container, .login-fields-inner-container:after {
  height: 59px;
}

.login-fields-inner-container:focus:after {
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(90deg, #FF553C 2.94%, #FF960B 100%);
}


.line {
  height: 2px;
  flex-shrink:0;
  align-self: stretch;
}

.login-input-field {

  width: 100%;
  height: 59px;
  gap: 16px;
  margin-top: 16px;
}

.informational {
  //gap: 16px;
  padding-bottom: 0;
  align-self: stretch;
  display: block;
  flex-direction: column;
  width: 100%;
}

.informational::placeholder {
  //gap: 16px;
  padding-bottom: 0;
  align-self: stretch;
  display: block;
  flex-direction: column;
  width: 100%;
}

.error {
  display: flex;
  width: 100%;
  height: 36px;
  color: #CB0000;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  align-items: center;
  justify-content: center;
}

.button-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: center;
  justify-content: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 35px 0px;
  gap: 30px;
  width: 100%;

}

.bottom-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 30px;
  width: 100%;
  color: #241e3f;
}

.bottom-text {
  //width: 310px;
  line-height: 26px;
  text-align: center;
  text-decoration-line: underline;
  color: #5A6A7B;
  padding-bottom: 25px;
}
@media (min-width: 840px) {
.bottom-text {
  //width: 310px;
  line-height: unset;
  text-align: center;
  text-decoration-line: underline;
  color: #5A6A7B;
  padding-bottom: unset;
}
}
.bottom-text-two {
  //width: 310px;
  line-height: 26px;
  text-align: center;
  text-decoration-line: underline;
  color: #5A6A7B;
}
@media (min-width: 840px) {
.bottom-text-two {
  display: none;
}
}
.bottom-text-plain {
  //width: 310px;
  font-size: 17px;
  line-height: 26px;
  font-weight: 500;
  text-align: center;
  text-decoration-line: none;
  color: #5A6A7B;
  padding-bottom: 0px;
}
@media (min-width: 840px) {
.bottom-text-plain {
  display: none;
}
}
