.onBoarding {
  background: #D6D8DD;
  z-index: 1;
}
@media (min-width: 840px) {
.onBoarding {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #231C3F;
  min-height: calc(100vh - 130px);
}
}
