@import "variables";


.container {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  padding: 0px;

  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $modal-screen-container-background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100%;

  overflow: auto;
}
@media (min-width: 840px) {
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $modal-screen-container-background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100vh;

  overflow: auto;
}
}



.modal-addEmail-container {
  position: absolute;
  display: flex;
  padding: 71px 0px;
  //margin-top: 71px;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  height: 100%;
  width: 100%;
  //border-top-left-radius: 20px;
  //border-top-right-radius: 20px;
  background: $card-background-color;
}
@media (min-width: 840px) {
.modal-addEmail-container {
  position: relative;
  //top: 25%;
  max-width: 500px;
  padding: 30px 0px;
  //margin-top: unset;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 20px;
  height: unset;
  box-shadow: 0px 0px 30px 0px #272041;
  background: $card-background-color;
}
}

.email-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;
  align-self: stretch;
}
.inner-div {
  gap: 16px;
  width: 100%;
}

.email-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.email-form-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 30px;
  gap: 20px;
}

.email-form-header-text {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;

  /* Header on white */
  color: #2F364B;
}

.email-input-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  gap: 16px;
  width: 100%;

}


  .modal-inner-container {
    display: flex;
    padding: 0px 30px;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
  }

  .modal-header {
    display: flex;
    padding: 0px 20px 30px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    align-self: stretch;
  }

  .modal-header-text {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }


  .modal-fields {
    display: flex;
    padding-bottom: 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
  }

.addEmail-header {
display: flex;
position: fixed;
flex-direction: column;
width: 100vw;
background: transparent;
height: 71px;
}
@media (min-width: 840px) {
.addEmail-header {
display: flex;
position: unset;
flex-direction: column;
align-self: stretch;
width: unset;
background: unset;
height: unset;
}
}
  .back-button {
    position: absolute;
    width: 34px;
    height: 34px;
    left: 20px;
    top: 20px;
    padding: 0;
  }

  .modal-container-informative {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px 15px;
    gap: 20px;
    width: unset !important;
    height: unset !important;
    //width: 300px; /* adjust these to by dynamic */
    //height: 172px;
    @include modal-screen-container-informative-color;
  }

  .modal-content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 10px;
    gap: 10px;

    width: 270px;
    height: 70px;
  }

  .modal-title {
    width: 250px;
    height: 22px;
    color: $modal-title-color;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  .modal-description {
    width: 250px;
    height: 38px;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $modal-description-color;
  }

  .action-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;

    width: 270px;
    height: 47px;
  }

  .modal-button {
    gap: 10px;
    width: 270px;
    height: 47px;
    padding: 15px 30px 12px;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    cursor: pointer;
  }

  .modal-button-label {
    color: $enabled-button-text;
    width: 23px;
    height: 20px;
    font-weight: 600;
    text-align: center;
  }


.error {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 36px;
  color: #CB0000;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  align-items: center;
  margin-top: 16px;
}
.button-div {
  width: 100%;
  padding: 21px 30px 0px 30px;
  justify-content: center;
  align-items: center;
}
