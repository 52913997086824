.main-container {
  background: #231C3F;
  display: flex;
  padding: 0px 25px 0px 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 360px;
  width: 100%;
  position: relative;
}

@media (min-width: 501px) {
.main-container {
  background: #231C3F;
  min-height: calc(100vh - 130px);
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  position: relative;
}
}
