.main-container {
  //background: radial-gradient(53.24% 53.24% at 50% 53.24%, #302753 0%, #261F44 80.21%, #231C3F 100%);
  overflow: hidden;
  display: flex;
  padding: 0px 25px 0px 25px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 360px;
  width: 100%;
  position: relative;
}

@media (min-width: 501px) {
.main-container {
  //background: radial-gradient(53.24% 53.24% at 50% 53.24%, #302753 0%, #261F44 80.21%, #231C3F 100%);
  overflow: hidden;
  min-height: calc(100vh - 130px);
  display: flex;
  padding: 0px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 500px;
  position: relative;
}
}
