.settings-background {
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100vw;
//  background: #231C3F;
  background: rgba(35, 28, 63, 0.5);
  //height: calc(100% - 120px);
  //height: 100vh;
  //height: calc(100vh - env(safe-area-inset-bottom));
  //height: -webkit-fill-available;
  //height: unset;
  //height: 100%;
}
@media (min-width: 840px) {
.settings-background {
  display: flex;
  overflow: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 99;
  //position: absolute;
  position: fixed;
  width: 100vw;
//  background: #231C3F;
  background: rgba(35, 28, 63, 0.5);
  //height: calc(100% - 150px);
  //height: 100%;
  padding-bottom: unset;
}
}

.settings-none {
  display: none;
}
.settings-container {
display: flex;
position: relative;
//height: 100vh;
//height: calc(100vh - env(safe-area-inset-bottom));
//height: -webkit-fill-available;
//height: unset;
flex-direction: column;
align-items: center;
align-self: stretch;
//overflow: hidden;
background: #F9F6F4;
//box-shadow: 0px 0px 30px 0px #272041;
height: 100%;
overflow-y: auto;
}
@media (min-width: 840px) {
.settings-container {
display: flex;
height: unset;
max-width: 700px;
flex-direction: column;
align-items: flex-start;
align-self: unset;
border-radius: 20px;
overflow: hidden;
position: relative;
background: #F9F6F4;
box-shadow: 0px 0px 30px 0px #272041;
flex-shrink: 0;
}
}

.settings-main-header-container {
display: flex;
position: static;
width: 100vw;
flex-direction: column;
align-self: stretch;
padding: 12px 0px 18px 0px;
justify-content: center;
align-items: center;
gap: 14px;
background: #FFF;
}
@media (min-width: 840px) {
.settings-main-header-container {
display: flex;
position: unset;
width: unset;
padding: 22px 0px 18px 0px;
flex-direction: column;
align-self: unset;
justify-content: center;
align-items: center;
gap: 14px;
align-self: stretch;
background: #FFF;
}
}
.settings-exit-button {
  width: 34px;
  height: 34px;
  position: absolute;
  right: 20px;
  top: 22px;
  cursor: pointer;
}
.settings-back-button {
  width: 34px;
  height: 34px;
  position: absolute;
  left: 20px;
  top: 12px;
  cursor: pointer;
}

.settings-main-header {
display: flex;
align-items: flex-start;

color: #000;
text-align: center;
font-size: 28px;
font-family: Gilroy;
font-style: normal;
font-weight: 700;
line-height: normal;
}


.settings-inner-container {
display: flex;
padding: 20px 10px 25px;
width: 100%;
gap: 35px;
flex-direction: column;
align-items: center;
}
@media (min-width: 840px) {
.settings-inner-container {
display: flex;
padding: unset;
gap: unset;
flex-direction: unset;
width: 840px;
align-items: flex-start;
}
}

.settings-sidebar {
  display: flex;
  width: 220px;
  height: 350px;
  padding: 20px 30px 30px 30px;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 15px 0px 0px 15px;
}

.settings-sidebar-buttons {
  display: flex;
  width: 160px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  align-self: stretch;
}

.settings-sidebar-1 {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  
  border-radius: 15px;
  background: transparent;
  border: 0;
}
.settings-sidebar-1 {
  outline: none;
}
.settings-sidebar-1:hover {
  background: rgba(255, 255, 255, 0.70);
}

.settings-sidebar-1-highlight {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  
  border-radius: 15px;
  background: #FFF;
  border: 0;
}
.settings-sidebar-1-highlight {
  outline: none;
}

.settings-sidebar-2 {
display: flex;
padding: 14px;
align-items: center;
flex: 1 0 0;
  border: 0;
}

.settings-sidebar-3 {
display: flex;
flex-direction: row;
align-items: center;
flex: 1 0 0;

color: var(--header-on-white, #2F364B);
font-size: 17px;
font-family: Gilroy;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.settings-right {
display: flex;
padding: 30px 30px 30px 0px;
//height: 350px;
//overflow: hidden;
//overflow: auto;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
}

.settings-notifications {
display: flex;
gap: 6px;
max-width: 650px;
width: 100%;
flex-direction: column;
align-items: flex-start;
//align-self: stretch;
}
@media (min-width: 840px) {
.settings-notifications {
display: flex;
gap: 6px;
max-width: 450px;
width: 100%;
flex-direction: column;
align-items: flex-start;
//align-self: stretch;
}
}

.notifications-body {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
}

.notifications-toggle-container {
display: flex;
padding: 1px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
}

.notifications-toggle-container:focus {
//outline: none;
//box-shadow: none;
display: flex;
padding: 1px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
}

.notifications-toggle-container:focus .notifications-toggle{
//outline: none;
//box-shadow: none;
}

.notifications-toggle {
display: flex;
width: 51px;
height: 31px;
justify-content: center;
align-items: center;
border: 0;
background: transparent;
outline:  none;
box-shadow: none;
-webkit-tap-highlight-color: transparent;
}

.settings-account {
display: flex;
gap: 6px;
max-width: 650px;
height: 290px;
width: 100%;
flex-direction: column;
align-items: flex-start;
//align-self: stretch;
}
@media (min-width: 840px) {
.settings-account {
display: flex;
gap: 6px;
max-width: 450px;
height: 290px;
width: 100%;
flex-direction: column;
align-items: flex-start;
//align-self: stretch;
}
}
//header
.settings-header-container {
display: flex;
padding-bottom: 0px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}
//label
.settings-header {
padding: 0px 25px 8px 25px; 
display: flex;
flex-direction: column;
align-self: stretch;

color: var(--Header-on-white, #2F364B);
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 19px;
}
//content container
.account-body {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
}

.settings-subscription {
display: flex;
max-width: 650px;
width: 100%;
flex-direction: column;
align-items: flex-start;
gap: 20px;
//align-self: stretch;
}
@media (min-width: 840px) {
.settings-subscription {
display: flex;
max-width: 450px;
width: 100%;
flex-direction: column;
align-items: flex-start;
gap: 20px;
//align-self: stretch;
}
}
//plan container
.subscription-top {
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
gap: 6px;
}
//header
.subscription-header-container {
display: flex;
padding: 0px 25px 8px 25px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}
//label
.subscription-header {
display: flex;
flex-direction: column;
align-self: stretch;

color: var(--Header-on-white, #2F364B);
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 19px; /* 111.765% */
}
//content container
.subscription-top-body {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
}

.settings-row-left-text-container {
display: flex;
padding: 2px 14px 0px 0px;
align-items: flex-start;
align-self: stretch;
}
//label
.settings-row-left-text {
display: flex;
flex-direction: row;
justify-content: center;
align-self: stretch;
align-items: center;

color: #000;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
}
//value container
.settings-row-right-text-container {
display: flex;
padding: 2px 0px 0px 14px;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
}
//value
.settings-row-right-text {
display: flex;
flex-direction: row;
justify-content: center;
flex: 1 0 0;
align-self: stretch;
align-items: center;

color: #5A6A7A;
text-align: right;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
}

//payment container
.subscription-bottom {
display: flex;
max-width: 450px;
flex-direction: column;
align-items: flex-start;
gap: 6px;
}
//content container
.subscription-bottom-body {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
align-self: stretch;
}

.settings-monitoring {
display: flex;
gap: 6px;
max-width: 650px;
flex-direction: column;
align-items: flex-start;
//align-self: stretch;
}
@media (min-width: 840px) {
.settings-monitoring {
display: flex;
gap: 6px;
max-width: 450px;
flex-direction: column;
align-items: flex-start;
//align-self: stretch;
}
}
//groups container
.monitoring-body-container {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
}
//emails container
.monitoring-body {
display: flex;
padding: 25px;
flex-direction: column;
align-items: flex-start;
border-radius: 15px;
background: #FFF;
}
@media (min-width: 840px) {
.monitoring-body {
display: flex;
max-width: 450px;
//height: 194px;
//overflow: auto;
padding: 25px;
flex-direction: column;
align-items: flex-start;
border-radius: 15px;
background: #FFF;
}
}
//description
.monitoring-description-container {
display: flex;
padding-bottom: 20px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}
//text
.monitoring-description {
display: flex;
flex-direction: column;
align-self: stretch;

color: var(--messaging-on-white, #545E75);
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
//emails container
.monitoring-list {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
}
//emails container
.monitoring-row {
display: flex;
padding: 0px 18px;
height: 41px;
align-items: flex-start;
align-self: stretch;
border-radius: 70px;
background: #F9F6F4;
}
//label container
.monitoring-email-container {
display: flex;
padding: 11px 15px 9px 0px;
height: 41px;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
//email
.monitoring-email {
color: #5A6A7A;
text-align: right;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
//icon container
.monitoring-icon {
width: 14px;
align-self: stretch;
}

.settings-payment-history {
display: flex;
flex-direction: column;
justify-content: center;
position: absolute;
top: 0%;
left: calc(50% - 700px/2);
z-index: 1;
height: calc(100vh - 120px);
background: #231C3F;
}

.settings-row-container {
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.settings-row {
display: flex;
height: 60px;
padding: 0px 25px;
align-items: flex-start;
align-self: stretch;
border-radius: 15px;
background: #FFF;
}

.settings-row-icon-container {
display: flex;
padding: 1px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
}

.settings-row-icon {
width: 24px;
height: 24px;
}

.settings-row-text-container {
display: flex;
padding: 2px 14px 0px 14px;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
outline: none;
}
.settings-row-text {
display: flex;
flex-direction: row;
justify-content: center;
align-self: stretch;
align-items: center;

color: var(--header-on-white, #2F364B);
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
}

.settings-row-right-container {
display: flex;
padding: 1px 0px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
align-self: stretch;
}
//right arrow
.settings-row-arrow {
width: 8px;
height: 11.5px;
}

.settings-row-left-container {
  display: flex;
  padding: 1px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
}

.settings-subscription-error {
  background: unset;
}

.settings-subscription-loader {
  background: unset;
}
