@import "variables";

.passwordreset-row {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  height: 100%;
  width: 100%;
  background: #FFF;
}
@media (min-width: 840px) {
.passwordreset-row {
  display: flex;
  position: unset;
  top: unset;
  left: unset;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  height: unset;
  max-width: 500px;
  width: 100%;
  min-height: 382px;
  background: unset;
}
}

.passwordreset-account {
  display: flex;
  position: static;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px 0px;
  isolation: isolate;

  max-width: 600px;
  width: 100%;
  height: 100%;

  background: #FFFFFF;
}
@media (min-width: 840px) {
.passwordreset-account {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px 0px;
  isolation: isolate;

  max-width: 500px;
  width: 100%;
  min-height: 382px;
  height: unset;

  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
}
}

.passwordreset-form {
  display: flex;
  max-width: 600px;
  width: 100%;
}
@media (min-width: 840px) {
.passwordreset-form {
  display: flex;
  max-width: 500px;
  width: unset;
}
}

.passwordreset-back {
  position: absolute;
  width: 34px;
  min-height: 34px;
  left: 20px;
  top: 20px;
  padding: 0;
}

.passwordreset-inner-container {
  display: flex;
  flex-direction: column;

  align-items: center;
  padding: 0px 30px;
  min-height: 322px;
  width: 100%;
}

.passwordreset-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  padding: 0px 20px 30px;
  justify-content: center;
  min-height: 131px;
  max-width: 440px;
  width: 100%;
}

.passwordreset-header-text {
  left: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  text-align: center;
  /* Header on white */
  color: #2F364B;
  max-width: 400px;
  width: 100%;
  min-height: 35px;
}

.passwordreset-informational {
  max-width: 400px;
  width: 100%;
  min-height: 46px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: $message-on-white;
}

.passwordreset-input-fields {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  padding: 0px 0px 16px;
  gap: 16px;
  width: 100%;
  min-height: 127px;


}
.passwordreset-input-field {
  display: flex;
  width: 100%;
  height: 59px;
}

.passwordreset-input-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 16px;

  position: relative;
  height: 59px;
  left: 0px;
  right: 0px;
  top: 0px;
  width: 100%;
}

.link {
  cursor: pointer;
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
}
.mInput {
  height: 59px;
}

.button {
  cursor: pointer;
}


.passwordreset-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 35px 0px;
  gap: 30px;
  align-self: stretch;
  width: 100%;
  height: 56px;
}
.passwordreset-bottom-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;
  max-width: 370px;
  width: 100%;
  height: 21px;

}

.passwordreset-bottom-text {

  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  /* identical to box height */
  text-align: center;
  text-decoration-line: underline;
  max-width: 310px;
  width: 100%;
  height: 21px;
  color: #5A6A7B;
}
