.login {
  display: flex;
  justify-content: center;
//  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: row;
  position: absolute;
//  left: calc(50% - 500px/2);
}
@media (min-width: 501px) {
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: row;
  position: relative;
}
}

.login-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;

}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0px;
  isolation: isolate;

  //width: 500px;
  width: 100%;
  max-width: 500px;
  height: 447px;

  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
}
@media (min-width: 501px) {
.login-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 0px;
  isolation: isolate;

  //width: 500px;
  width: 100%;
  max-width: 500px;
  height: 447px;

  background: #FFFFFF;
  box-shadow: 0px 0px 30px #272041;
  border-radius: 20px;
}
}

.login-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 30px;
  width: 100%;
  max-width: 500px;
  height: 387px;
}

.login-header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 20px 30px;
  gap: 20px;

  max-width: 440px;
  height: 65px;

}

.login-header-text {
  max-width: 400px;
  height: 35px;

  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;

  /* identical to box height */
  text-align: center;

  /* Header on white */
  color: #2F364B;
}

.login-fields-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  gap: 16px;

  max-width: 440px;
  height: 202px;
}

.login-input-field {
  max-width: 440px;
  height: 59px;

}

.login-input-inner-container {
  display: flex;
  flex-direction: column;
  padding: 0px;
  gap: 16px;
  align-items: center;
  position: relative;
  height: 59px;
  left: 0px;
  right: 0px;
  top: 0px;
}

//Label overrides for input fields
.mLabel {
  display: flex;
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 9px;
  width: 100%;
  height: 59px;

  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;

  /* identical to box height */
  text-align: center;

  /* Input Value on white */
  color: #2F364B;
}



.error {
  width: 100%;
  height: 36px;
  color: #CB0000;
  text-align: center;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  font-style: normal;
  justify-content: center;
  align-items: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 35px 0px;
  gap: 30px;

  max-width: 440px;
  height: 56px;
}

/* Transition Bug in firefox
https://stackoverflow.com/questions/55725461/webkit-background-clip-text-on-an-element-with-transition-is-not-working-after
https://bugzilla.mozilla.org/show_bug.cgi?id=1545128
*/
