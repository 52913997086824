@import "variables";

.modal-screen-container {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.40);
  gap: 20px;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  padding: 20px 15px 15px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: auto;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px #272041;
  max-width: 400px;
}

.modal-content {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: center;
}

.modal-title {
  color: #1B122C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-description {
  color: #5A6A7A;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}

.button-Ok {
  display: flex;
  padding: 15px 30px 12px 30px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 15px;
  background: linear-gradient(0deg, #231C3F 0%, #231C3F 100%), linear-gradient(0deg, #535256 0%, #535256 100%), #505050;
  box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
  cursor: pointer;
}

.modal-button-label {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

