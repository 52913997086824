.upgrade-page-main-container {
display: flex;
overflow-y: auto;
position: relative;
//height: 1364px;
max-width: 839px;
min-width: 360px;
width: 100%;
height: 100%;
justify-content: center;
align-items: flex-start;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%), #231C3F;
}
@media (min-width: 840px) {
.upgrade-page-main-container {
display: flex;
overflow-y: unset;
position: relative;
//height: 1364px;
max-width: 650px;
min-width: 360px;
width: 100%;
height: unset;
justify-content: center;
align-items: flex-start;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%), #231C3F;
border-radius: 20px;
}
}

.upgrade-page-upgrade-container {
display: flex;
max-width: 650px;
min-width: 360px;
width: 100%;
flex-direction: column;
align-items: center;
//gap: 55px;
//background: #231C3F;
}
@media (min-width: 840px) {
.upgrade-page-upgrade-container {
display: flex;
max-width: 650px;
min-width: 360px;
width: 100%;
flex-direction: column;
align-items: center;
//gap: 55px;
//background: #231C3F;
}
}

.upgrade-page-card {
display: flex;
padding:  0px 20px 20px 20px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 20px;
background: transparent;
}
//@media (min-width: 650px) {
//.upgrade-page-card {
//display: flex;
//padding:  0px 20px 20px 20px;
//flex-direction: column;
//align-items: flex-start;
//align-self: stretch;
//border-radius: 20px;
//background: rgba(255, 255, 255, 0.06);
//}
//}

.upgrade-page-card-top {
display: flex;
padding: 0px 15px 25px 15px;
flex-direction: column;
align-items: flex-start;
gap: 25px;
align-self: stretch;
}

.upgrade-page-card-top-header {
display: flex;
flex-direction: column;
align-items: flex-start;
//gap: 6px;
align-self: stretch;
}

.upgrade-page-card-top-header-row {
display: flex;
  flex-direction: column;
justify-content: center;
align-items: flex-start;
//gap: 8px;
align-self: stretch;
}

.upgrade-page-card-top-header-row-mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  //gap: 8px;
  align-self: center;
}



@media (min-width: 840px) {
.upgrade-caret {
display: none;
}
}
.upgrade-caret {
position: absolute;
width: 34px;
height: 34px;
right: 20px;
top: 20px;
cursor: pointer;
}
.upgrade-exit-icon {
display: none;
}
@media (min-width: 840px) {
.upgrade-exit-icon {
display: flex;
position: absolute;
width: 34px;
height: 34px;
right: 20px;
top: 20px;
cursor: pointer;
}
}
.upgrade-page-exit-icon {
display: flex;
position: absolute;
width: 34px;
height: 34px;
right: 20px;
top: 20px;
cursor: pointer;
}
@media (min-width: 840px) {
.upgrade-page-exit-icon {
display: none;
}
}

.upgrade-page-card-top-header-row-text-one {
display: inline;
//padding: 7px 0px 0px 0px;
  padding-top: 7px;
align-items: flex-start;
align-self: stretch;
color: #FFF;
text-align: center;
font-size: 28px;
font-family: Gilroy;
font-weight: 600;
}

.upgrade-page-card-top-header-row-text-two {
//display: flex;
padding-top: 6px;
align-items: flex-start;
align-self: stretch;
color: #FFF;
text-align: center;
font-size: 28px;
font-family: Gilroy;
font-weight: 600;
}

.upgrade-page-card-top-header-bold-text {
  display: inline;
  //padding: 7px 0px 0px 0px;
  align-items: flex-start;
  align-self: stretch;
  color: #FFF;
  text-align: center;
  font-size: 28px;
  font-family: Gilroy;
  font-weight: 700;
  line-height: 1;
}

.upgrade-page-card-top-header-row-tag {
display: inline-flex;
align-items: flex-start;
padding: 6px 14px 4px 14px;
justify-content: center;
border-radius: 20px;
background: var(--mirkat-theme, linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%));
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 24px;
font-weight: 700;
font-style: normal;
line-height: normal;
}

.upgrade-page-card-top-text {
display: flex;
flex-direction: column;
align-self: stretch;
color: #FFF;
text-align: center;
font-size: 20px;
font-family: Gilroy;
font-weight: 500;
line-height: 26px;
}

.upgrade-page-card-exposed-data {
display: flex;
padding: 15px 0px;
flex-direction: column;
align-items: flex-start;
gap: 15px;
align-self: stretch;
border-radius: 15px;
}

.upgrade-item-container {
display: flex;
width: 90px;
flex-direction: column;
align-items: center;
flex-basis: 33.3%;
}
@media (min-width: 840px) {
.upgrade-item-container {
display: flex;
width: 100px;
flex-direction: column;
align-items: flex-start;
flex-basis: unset;
}
}

.upgrade-items-row {
display: flex;
justify-content: center;
align-items: center;
align-content: center;
row-gap: 15px;
align-self: stretch;
flex-wrap: wrap;
}
@media (min-width: 550px) {
.upgrade-items-row {
display: flex;
justify-content: center;
align-items: center;
align-content: center;
row-gap: 15px;
align-self: stretch;
flex-wrap: unset;
}
}

.upgrade-item {
width: 100px;
height: 55px;
}

.upgrade-item-header {
width: 100px;
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal; 
}

.upgrade-page-card-button-container {
display: flex;
padding: 30px 15px 0px 15px;
flex-direction: column;
align-items: center;
gap: 30px;
align-self: stretch;
backdrop-filter: blur(2px);
}

.upgrade-page-card-button-inner-container {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
align-self: stretch;
}

.upgrade-page-card-buttons {
display: flex;
flex-direction: column;
align-items: center;
gap: 15px;
align-self: stretch;
color: #FFF;
font-size: 18px;
font-family: Gilroy;
font-weight: 500;
}


.upgrade-page-card-button-one {
display: flex;
padding: 20px 20px 17px 20px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 15px;
background: linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%);
box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
color: #FFF;
font-size: 20px;
font-family: Gilroy;
font-weight: 600;
text-decoration: unset;
  line-height: 1;
}

.upgrade-page-tagline {
align-self: stretch;

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.upgrade-page-card-button-two {
display: none;
padding: 15px 40px 12px 40px;
justify-content: center;
align-items: center;
gap: 10px;
flex: 1 0 0;
align-self: stretch;
border-radius: 15px;
color: #FFF;
text-align: center;
font-size: 20px;
font-family: Gilroy;
font-weight: 500;
text-decoration-line: underline;
}
@media (min-width: 840px) {
.upgrade-page-card-button-two {
display: flex;
padding: 15px 40px 12px 40px;
justify-content: center;
align-items: center;
gap: 10px;
flex: 1 0 0;
align-self: stretch;
border-radius: 15px;
color: #FFF;
text-align: center;
font-size: 20px;
font-family: Gilroy;
font-weight: 500;
text-decoration-line: underline;
}
}

.upgrade-page-why {
display: flex;
padding: 50px 20px 40px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 20px;
//background: #231C3F;
}
@media (min-width: 840px) {
.upgrade-page-why {
display: flex;
padding: 35px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 20px;
//background: linear-gradient(0deg, rgba(255, 255, 255, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%), #231C3F;
}
}

.upgrade-page-why-header {
display: flex;
padding: 0px 0px 30px 0px;
flex-direction: column;
align-items: center;
gap: 15px;
align-self: stretch;
color: #FFF;
text-align: center;
font-size: 24px;
font-family: Gilroy;
font-weight: 700;
line-height: 26px;
}

.upgrade-page-why-items-container {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 35px;
align-self: stretch;
}

.upgrade-page-why-item {
display: flex;
align-items: flex-start;
gap: 15px;
align-self: stretch;
}

.upgrade-page-why-item-icon {
display: flex;
width: 34px;
height: 34px;
justify-content: center;
align-items: flex-start;
}

.upgrade-page-why-item-content {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 6px;
flex: 1 0 0;
color: #FFF;
font-size: 20px;
font-family: Gilroy;
font-weight: 600;
line-height: 25px;
}

.upgrade-page-why-item-content-two {
color: #FFF;
font-size: 18px;
font-family: Gilroy;
line-height: 25px;
font-weight: 400;
}

.welcome-link {
color: #FFFFFF
}
