.welcome-page {
display: flex;
position: fixed;
height: 100%;
width: 100%;
flex-direction: column;
justify-content: center;
align-items: center;
background: #FFF;
z-index: 2;
overflow-y: auto;
}
@media (min-width: 840px) {
.welcome-page {
display: flex;
min-height: calc(100vh - 130px);
height: unset;
width: unset;
flex-direction: column;
justify-content: center;
align-items: center;
background: #231C3F;
position: relative;
z-index: 2;
overflow-y: unset;
}
}

.welcome-page-main {
display: flex;
//height: 100vh;
min-height: 100vh;
align-items: center;
}
@media (min-width: 840px) {
.welcome-page-main {
display: flex;
//height: 1034px;
min-height: unset;
align-items: center;
}
}

.welcome-page-welcome {
//position: absolute;
display: flex;
flex-direction: column;
align-items: space-between;
justify-content: space-between;
max-width: 600px;
width: 100%;
//height: 100vh;
overflow-y: auto;
padding: 30px 0px;
background: #FFF;
}
@media (min-width: 840px) {
.welcome-page-welcome {
//position: absolute;
display: flex;
max-width: 500px;
width: 100%;
//max-height: 537px;
height: 100%;
padding: 30px 0px;
flex-direction: column;
align-items: flex-start;
border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 30px 0px #272041;
}
}

.welcome-page-welcome-inner {
display: flex;
padding: 0px 30px;
max-width: 600px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.welcome-page-welcome-inner-icon-container {
display: flex;
height: 120px;
max-width: 600px;
justify-content: center;
align-items: center;
align-self: stretch;
}
@media (min-width: 840px) {
.welcome-page-welcome-inner-icon-container {
display: flex;
height: 120px;
max-width: 440px;
justify-content: center;
align-items: center;
align-self: stretch;
}
}

.welcome-page-welcome-inner-icon {
display: flex;
width: 120px;
height: 120px;
justify-content: center;
align-items: center;
}

.welcome-page-welcome-inner-header {
display: flex;
padding: 20px 0px;
flex-direction: column;
align-items: center;
gap: 20px;
align-self: stretch;
}


.welcome-page-welcome-inner-header-text {
display: flex;
padding: 2px 0px 0px 0px;
justify-content: center;
align-self: stretch;
align-items: center;
color: var(--header-on-white, #2F364B);
text-align: right;
font-size: 28px;
font-family: Gilroy;
font-weight: 700;
}


.welcome-page-welcome-inner-body {
display: flex;
padding: 0px 0px 40px;
flex-direction: row;
align-items: flex-start;
gap: 20px;
align-self: stretch;
color: var(--messaging-on-white, #545E75);
text-align: center;
font-size: 19px;
font-family: Gilroy;
font-weight: 500;
line-height: 25px;
white-space: pre-line;
}

.welcome-page-welcome-inner-button {
display: flex;
margin: 0px;
padding: 24px 40px 20px 40px;
flex-direction: column;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 15px;
background: linear-gradient(0deg, #231C3F 0%, #231C3F 100%), linear-gradient(0deg, #535256 0%, #535256 100%), #505050;
box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);

text-decoration: none;
color: #FFF;
text-align: center;
font-size: 17px;
font-family: Gilroy;
font-weight: 700;
  line-height: 1
}


.welcome-page-welcome-inner-header-row {
display: flex;
justify-content: center;
align-items: center;
gap: 8px;
align-self: stretch;
}

.welcome-page-welcome-inner-header-row-tag {
display: flex;
padding: 6px 14px 4px 14px;
align-items: flex-start;
border-radius: 20px;
background: var(--mirkat-theme, linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%));
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 28px;
font-weight: 700;
font-style: normal;
line-height: normal;
}
