@import "variables";

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  background: #231C3F;
}

.footer-inner-container {
  display: flex;
  max-width: 100%;
  //padding: 2px 0px 10px 0px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
}

.footer-links-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 2px 0px 10px 0px;
}

.footer-link a {
  color: $message-on-dark;
  text-decoration: none;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
}

.build-text {
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-left: auto;
  margin-right: 10px;
  margin-bottom: 0px;
  color: $message-on-dark;
  text-align: center;
  font-size: 13px;
  font-weight: 500;

}

.copyright-container {
  //position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 10px;
}

.copyright-text {
  //position: absolute;
  color: $message-on-dark;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
