@import "variables";

.modal-screen-container {
  display: flex;
  width: 100%;
  height: 100vh;
  padding: 0px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  display: flex;
  padding: 20px 15px 15px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  width: auto;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px #272041;
}

.modal-content {
  display: flex;
  padding: 0px 10px;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  justify-content: center;
}

.modal-title {
  color: #1B122C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.modal-description {
  color: #5A6A7A;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.action-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}


