.feedcard {
  display: flex;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out, margin-top 0.5s ease-out, max-height 0.5s ease-out;
  max-height: 2200px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  transition: all 0.3s ease;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  text-align: center;

  color: #545E75;
}
@media (min-width: 501px) {
.feedcard {
  display: flex;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out, margin-top 0.5s ease-out, max-height 0.5s ease-out;
  max-height: 2200px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-width: 500px;
  transition: all 0.3s ease;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  text-align: center;

  color: #545E75;
}
}
.feedcard-moved {
  display: flex;
  opacity: 0;
  max-height: 0;
  margin-top: -20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  text-align: center;

  color: #545E75;
}
@media (min-width: 501px) {
.feedcard-moved {
  display: flex;
  opacity: 0;
  max-height: 0;
  margin-top: -20px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  max-width: 500px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  text-align: center;

  color: #545E75;
}
}
.feedcard-history {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
  border-radius: 20px;
  max-width: 460px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;

  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  
  text-align: center;

  color: #545E75;
}
.feedcard-image {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: auto;
  width: 100%;
}
@media (min-width: 501px) {
.feedcard-image {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  max-width: 500px;
  height: 277px;
}
}

.feedcard-status-container {
height: 58px;
display: flex;
padding: 17px 0px 15px 0px;
justify-content: center;
align-items: center;
flex: 1 0 0;
align-self: stretch;
}

.feedcard-status {
display: flex;
padding: 4px 16px 4px 16px;
align-items: center;
justify-content: center;
border-radius: 10px;
background: var(--risk, linear-gradient(0deg, #FF5154 0%, #FF5154 100%), #FFF);

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 800;
line-height: 1.0;
letter-spacing: 1.4px;
}

.feedcard-spacer {
padding-bottom: 20px;
}

.feedcard-bottom-spacer {
padding-bottom: 14px;
}

.feedcard-title {
display: flex;
padding: 0px 30px 16px 30px;
align-items: flex-start;
//align-self: stretch;
justify-content: center;

color: var(--header-on-white, #2F364B);
text-align: center;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 113.636% */
}

.feedcard-description-container {
display: flex;
padding: 0px 20px 30px 20px;
align-items: flex-start;
//align-self: stretch;
}

.feedcard-description {
align-self: stretch;
flex: 1 0 0;
color: var(--messaging-on-white, #545E75);
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
}

.feedcard-header {
  margin-bottom: 15px;
  height: 23px;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  text-align: center;
  color: #2F364B;
}

.feedcard-expand {
  margin: 16px 0 0;
  padding: 0;
  list-style-type: none;
}

.feedcard-expand {
  margin: 16px 0 0;
  padding: 0;
  list-style-type: none;
}

.feedcard-info {
  margin: 8px 0;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.feedcard-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 9px;
  padding: 0px 20px 20px 20px;
  align-self: stretch;
}

.feedcard-row {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
}
.feedcard-label-container {
display: flex;
justify-content: flex-start;
//width: 180px;
flex: 0 0 calc(40% - 10px);
padding: 5px 0px 5px 15px;
align-items: center;
align-self: stretch;
}
.feedcard-label {
flex: 1 0 0;
color: var(--messaging-on-white, #545E75);
leading-trim: both;
text-align: left;
text-edge: cap;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.feedcard-label-password-container {
display: flex;
padding: 12px 0px 9px 0px;
align-items: flex-start;
flex: 1 0 0;
}
.feedcard-label-password {
flex: 1 0 0;
color: var(--messaging-on-white, #545E75);
text-align: right;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.feedcard-value-container {
display: flex;
padding: 15px;
//width: 258px;
flex: 0 0 calc(60% - 10px);
flex-direction: column;
justify-content: center;
align-items: flex-end;
gap: 10px;
flex: 1 0 0;
border-radius: 12px;
background: var(--exposed-data-value-background, #FAF6F4);
}
@media (min-width: 501px) {
.feedcard-value-container {
display: flex;
padding: 15px;
width: 270px;
flex-direction: column;
justify-content: center;
align-items: flex-end;
gap: 10px;
flex: 1 0 0;
border-radius: 12px;
background: var(--exposed-data-value-background, #FAF6F4);
}
}

.feedcard-value {
align-self: stretch;
display: flex;
flex-direction: column;
gap: 5px;
overflow-wrap: break-word;
white-space: normal;
word-break: break-word;
color: var(--exposed-data-value, #2F364B);
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
}
.feedcard-value-row {
align-self: stretch;
align-items: center;
display: flex;
flex-direction: row;
justify-content: flex-end;
overflow-wrap: break-word;
white-space: normal;
word-break: break-word;
color: var(--exposed-data-value, #2F364B);
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
}
.feedcard-value-char {
color: var(--exposed-data-value, #2F364B);
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
}
.feedcard-value-heavy {
color: var(--exposed-data-value, #2F364B);
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 1.0;
letter-spacing: 1.5px;
}
.feedcard-webkit-dashes {
color: var(--Exposed-Data-Value, #2F364B);
padding: 0px 0px 3px;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
letter-spacing: 1.5px; 
}
.feedcard-value-dashes {
color: var(--Exposed-Data-Value, #2F364B);
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 1.0;
letter-spacing: 1.5px; 
}
.feedcard-lite {
color: var(--exposed-data-value, #2F364B);
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 1.0;
}
.feedcard-message {
color: var(--Messaging-on-white, #545E75); 
text-align: right;
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 1.0;
}
.feedcard-value-mixed {
padding: 0px 0px 3px;
color: var(--exposed-data-value, #2F364B);
leading-trim: both;
text-edge: cap;
font-family: Gilroy;
font-size: 10px;
font-style: normal;
font-weight: 400;
line-height: 1.0;
letter-spacing: 1px;
}
.feedcard-value-password-container {
display: flex;
padding: 9px 15px 5px 15px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 12px;
background: var(--exposed-data-value-background, #FAF6F4);
}
.feedcard-value-password {
display: flex;
width: 119px;
flex-direction: column;
justify-content: center;
flex: 1 0 0;

color: var(--exposed-data-value, #2F364B);
font-family: Gilroy;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.card-button-container {
display: flex;
padding: 10px 20px 6px 20px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}
.card-button {
display: flex;
padding: 15px 40px 12px 40px;
justify-content: center;
align-items: flex-start;
gap: 10px;
align-self: stretch;
border-radius: 15px;
background: linear-gradient(0deg, #231C3F 0%, #231C3F 100%), linear-gradient(0deg, #535256 0%, #535256 100%), #505050;
//box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
border: none;

color: #FFFFFF;
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

.feedbutton-group {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.dismiss-button {
display: flex;
margin-bottom: 8px;
//padding: 15px 40px 12px 40px;
padding: 15px 0px 12px 0px;
justify-content: center;
align-items: flex-start;
gap: 10px;
flex: 1 0 0;
cursor: pointer;

color: #2C364C;
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
}

.card-details {
position: fixed;
overflow-y: hidden;
display: flex;
align-items: flex-start;
justify-content: center;
z-index: 999;
width: 100%;
height: 100%;
top: 0;
left: 0;
background: transparent;
}
@media (min-width: 429px) {
.card-details {
position: fixed;
overflow: hidden;
display: flex;
align-items: flex-start;
justify-content: center;
z-index: 999;
width: 100%;
height: 100%;
top: 0;
left: 0;
background: transparent;
}
}
@media (min-width: 840px) {
.card-details {
position: fixed;
overflow-y: auto;
display: flex;
align-items: center;
justify-content: center;
z-index: 999;
width: 100%;
height: 100vh;
top: 0;
left: 0;
background: rgba(35, 28, 63, 0.5);
}
}
