@keyframes slide {
  0%, 100% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0% 0;
  }
}


.progressBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 4px;
  width: 0%;
  z-Index: 9999;
  background: linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%);
}

.progressComplete {
  background-size: 200% 100% !important;
  animation: slide 1.5s ease-in-out infinite;
}