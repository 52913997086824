@import "variables";

.modal-container {
  display: flex;
  position: fixed;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $modal-screen-container-background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100%;

  overflow: auto;
}
@media (min-width: 840px) {
.modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: $modal-screen-container-background-color;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100vh;

  overflow: auto;
}
}
.modal-intro-inner-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;

  position: relative;
  left: 0px;
  top: 0px;
  background: #FFF;
}
@media (min-width: 840px) {
.modal-intro-inner-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  position: relative;
  left: 0px;
  top: 0px;
  background: unset;
}
}

.modal-intro-container {
  display: flex;
  min-width: 360px;
  max-width: 600px;
  width: 100%;
  height: 100%;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  padding: unset;
  //background: #FFF;
  //box-shadow: 0px 0px 30px 0px #272041;
}
@media (min-width: 840px) {
.modal-intro-container {
  display: flex;
  max-width: 500px;
  height: unset;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
//  top: 25%;
  padding: unset;
  border-radius: 20px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px #272041;
}
}

.modal-main-container {
  display: flex;
  height: 100%;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
@media (min-width: 840px) {
.modal-main-container {
  display: flex;
  height: unset;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
}
.modal-top-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
.modal-intro-header-wrapper {
display: flex;
position: fixed;
flex-direction: column;
width: 100vw;
background: #FFF;
}
@media (min-width: 840px) {
.modal-intro-header-wrapper {
display: flex;
position: unset;
flex-direction: column;
align-self: stretch;
width: unset;
background: unset;
}
}

.modal-intro-header-container {
  display: flex;
  padding: 12px 22px 18px 22px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
}
@media (min-width: 840px) {
.modal-intro-header-container {
  display: flex;
  padding: 22px 22px 18px 22px;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;
  position: relative;
}
}
.modal-email-icon {
  width: 60px;
  height: 60px;
  background-color: #231C3F;
  border-radius: 50%; /* This makes the background round */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2.5px;
  padding-top: 2.5px;
}

.modal-email-icon-svg {
  width: 40px;
  height: 30px;
}

.modal-into-caret {
  width: 34px;
  height: 34px;
  position: absolute;
  top: 12px;
  right: 22px;
  z-index: 1;
}
@media (min-width: 840px) {
.modal-into-caret {
  display: none;
  width: 34px;
  height: 34px;
  position: absolute;
  right: -10px;
  top: -10px;
}
}
.modal-into-exit-icon {
  display: none;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 22px;
  top: 22px;
}
@media (min-width: 840px) {
.modal-into-exit-icon {
  display: unset;
  width: 34px;
  height: 34px;
  position: absolute;
  right: 22px;
  top: 22px;
}
}

//.modal-intro-description-container {
//  display: flex;
//  padding: 90px 35px 35px 35px;
//  flex-direction: column;
//  align-items: flex-start;
//  gap: 20px;
//  align-self: stretch;
//}
//@media (min-width: 840px) {
.modal-intro-description-container {
  display: flex;
  padding: 0px 35px 35px 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
}
//}

.modal-intro-description-header {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  color: #000;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; 
}

.modal-intro-description-text {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  text-align: center;
  color: #545E75; 
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}

.modal-intro-description-emails-span {
  color: #545E75; 
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
}

.modal-intro-bottom-container {
  display: flex;
  padding: 30px 35px 50px 35px; 
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}
@media (min-width: 840px) {
.modal-intro-bottom-container {
  display: flex;
  padding: 30px 35px 35px 35px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
}
}

.modal-intro-bottom-inner-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
}

.modal-intro-bottom-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
}
