li {
  list-style-type: none;
}

.threatcard {
overflow-x: hidden;
overflow-y: hidden;
position: flex;
display: flex;
max-width: 839px;
min-width: 360px;
width: 100vw;
//max-height: 100vh;
//min-height: 100vh;
//padding: 0px 35px 0px 35px;
max-height: 100%;
min-height: 100%;
flex-direction: column;
align-items: center;
background: #FFF;
box-shadow: 0px 0px 30px 0px #272041;
}
//@media (min-width: 840px) {
//.threatcard {
//overflow-y: hidden;
//position: static;
//margin: auto 0;
//display: flex;
//max-width: 428px;
//min-width: 360px;
//width: 100vw;
////max-height: 100vh;
//min-height: unset;
//max-height: unset;
////padding: 0px 35px 0px 35px;
//flex-direction: column;
//align-items: center;
//border-radius: 20px;
//background: #FFF;
//box-shadow: 0px 0px 30px 0px #272041;
//}
//}
@media (min-width: 840px) {
.threatcard {
overflow-y: hidden;
position: static;
margin: auto 0;
display: flex;
max-width: 650px;
min-height: unset;
max-height: unset;
padding: 30px;
flex-direction: column;
align-items: center;
flex-shrink: 0;
border-radius: 20px;
background: #FFF;
box-shadow: 0px 0px 30px 0px #272041;
}
}
.threatcard-outer-container {
display: flex;
flex-direction: column;
height: 100%;
overflow-y: auto;
align-self: stretch;
align-items: center;
}
@media (min-width: 840px) {
.threatcard-outer-container {
display: flex;
max-width: 428px;
min-width: 360px;
flex-direction: column;
height: unset;
overflow-y: unset;
align-self: unset;
align-items: unset;
}
}
.threatcard-header-container {
display: flex;
position: static;
align-items: center;
background: #FFF;
}
@media (min-width: 840px) {
.threatcard-header-container {
position: unset;
align-items: unset;
background: unset;
align-self: stretch;
flex-direction: column;
}
}
.threatcard-header {
display: flex;
padding: 12px 22px 18px 22px; 
position: relative;
flex-direction: column;
align-items: center;
align-self: stretch;
width: 100vw;
}
@media (min-width: 840px) {
.threatcard-header {
display: flex;
padding: 22px 22px 18px 22px;
position: relative;
flex-direction: column;
align-items: center;
align-self: stretch;
width: unset;
}
}
@media (min-width: 840px) {
.threatcard-header {
display: flex;
padding: 0px 0px 20px;
position: relative;
flex-direction: column;
align-items: center;
align-self: stretch;
width: unset;
}
}
.threatcard-image {
  border-radius: 50%;
  transform: rotate(15deg);
  width: 60px;
  height: 60px;
}
@media (min-width: 840px) {
.threatcard-image {
  border-radius: 50%;
  transform: unset;
  width: 60px;
  height: 60px;
}
}

.threatcard-caret {
display: flex;
width: 34px;
height: 34px;
position: absolute;
right: 22px;
top: 12px;
cursor: pointer;
}
@media (min-width: 840px) {
.threatcard-caret {
display: none;
}
}
.threatcard-exit {
display: none;
}
@media (min-width: 840px) {
.threatcard-exit {
display: flex;
width: 34px;
height: 34px;
position: absolute;
right: 22px;
top: 12px;
cursor: pointer;
}
}
@media (min-width: 840px) {
.threatcard-exit {
width: 34px;
height: 34px;
position: absolute;
right: -10px;
top: -10px;
cursor: pointer;
}
}

.threatcard-inner-container {
display: flex;
flex-direction: column;
align-items: center;
max-width: 650px;
min-width: 360px;
}
//@media (min-width: 840px) {
//.threatcard-inner-container {
//display: flex;
//padding-top: unset;
//flex-direction: column;
//align-items: center;
//align-self: stretch;
//}
//}
@media (min-width: 840px) {
.threatcard-inner-container {
flex-direction: column;
padding-top: unset;
display: flex;
align-items: flex-start;
align-self: stretch;
max-width: unset;
min-width: unset;
}
}

.threatcard-left-container {
display: flex;
flex-direction: column;
align-items: flex-start;
}
@media (min-width: 840px) {
.threatcard-left-container {
display: flex;
padding: 0px 32px 30px;
flex-direction: column;
align-items: flex-start;
}
}

@media (max-width: 840px) {
.threatcard-left-header {
display: none
}
}
@media (min-width: 840px) {
.threatcard-left-header {
display: flex;
padding-bottom: 20px;
justify-content: center;
align-items: center;
gap: 15px;
align-self: stretch;

color: var(--header-on-white, #2F364B);
font-family: Gilroy;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
}
.threatcard-left-description {
display: flex;
white-space: pre-line;
padding: 0px 35px 35px 35px;
align-items: flex-start;
align-self: stretch;

text-align: left;
color: var(--messaging-on-white, #545E75);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
@media (min-width: 840px) {
.threatcard-left-description {
display: flex;
white-space: pre-line;
padding: 0px 0px 30px 0px;
align-items: flex-start;
align-self: stretch;
flex: 1 0 0;

text-align: left;
color: var(--messaging-on-white, #545E75);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
}

.threatcard-divider-container {
display: flex;
padding: 40px 35px 35px 35px;
align-items: flex-start;
align-self: stretch;
}
@media (min-width: 840px) {
.threatcard-divider-container {
display: none;
padding: 50px 30px 0px 30px;
align-items: flex-start;
align-self: stretch;
}
}

.threatcard-divider {
height: 1px;
width: 100%;
align-self: stretch;
background: var(--exposed-data-value-background, #FAF6F4);
}
@media (min-width: 840px) {
.threatcard-divider {
width: 1px;
height: unset;
align-self: stretch;
background: var(--exposed-data-value-background, #FAF6F4);
}
}

.threatcard-right-container {
display: flex;
gap: 20px;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0 ;
padding-bottom: 20px;
}


.threatcard-showtitle {
display: flex;
align-items: flex-start;
padding: 0px 35px 15px 35px;
gap: 8px;
color: var(--header-on-white, #2F364B);
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 23px; /* 135.294% */
}
@media (min-width: 840px) {
.threatcard-showtitle {
display: flex;
align-items: flex-start;
padding: unset;
width: 274.5px;
gap: 8px;
color: var(--header-on-white, #2F364B);
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 23px; /* 135.294% */
}
}

.threatcard-showbutton {
display: none;
width: 57px;
color: var(--messaging-on-white, #545E75);
text-align: right;
font-family: Gilroy;
font-size: 13px;
font-style: normal;
font-weight: 600;
line-height: 23px; /* 176.923% */
text-decoration-line: underline;
background: transparent;
border: 0;
}


.threatcard-subtitle {
display: flex;
justify-content: center;
align-items: center;
padding-bottom: 15px;
align-self: stretch;

color: var(--header-on-white, #2F364B);
font-family: Gilroy;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
@media (min-width: 840px) {
.threatcard-subtitle {
display: flex;
padding-bottom: unset;
justify-content: center;
align-items: center;
gap: 15px;
align-self: stretch;

color: var(--header-on-white, #2F364B);
font-family: Gilroy;
font-size: 24px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
}


.threatcard-display {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-self: stretch;
  gap: 9px;
}

.threatcard-header-row {
display: flex;
gap: 8px;
padding-bottom: 15px;
flex-direction: row;
align-items: flex-start;
align-self: stretch;
}

.threatcard-row {
display: flex;
padding: 0px 35px;
flex-direction: row;
gap: 10px;
align-items: flex-start;
align-self: stretch;
}
@media (min-width: 840px) {
.threatcard-row {
display: flex;
padding: unset;
flex-direction: row;
justify-content: space-between;
gap: 10px;
align-items: flex-start;
align-self: stretch;
}
}
.threatcard-label-container {
display: flex;
padding: 12px 0px 9px 0px;
align-items: flex-start;
width: 180.5px;
min-height: 39px;
}
.threatcard-label {
display: flex;
padding: 5px 0px;
flex: 0 0 calc(40% - 10px);
justify-content: flex-start;
align-items: center;
align-self: stretch;

white-space: nowrap;
color: var(--messaging-on-white, #545E75);
text-align: left;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.threatcard-value-container {
display: flex;
//padding: 9px 15px 5px 15px;
flex-direction: column;
align-self: stretch;
border-radius: 12px;
background: var(--exposed-data-value-background, #FAF6F4);
}

.threatcard-value {
display: flex;
gap: 5px;
padding: 15px;
flex-direction: column;
overflow-wrap: break-word;
white-space: normal;
word-break: break-word;
flex: 0 0 calc(60% - 10px);
justify-content: center;
align-items: right;
border-radius: 12px;
background: var(--exposed-data-value-background, #FAF6F4);

color: var(--Exposed-Data-Value, #2F364B); 
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-align: right;
}



.threatcard-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FAF6F4;
  border-radius: 20px;
  align-items: center;
  margin: 0px 10px 0px 10px;
}
@media (min-width: 840px) {
.threatcard-action {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #FAF6F4;
  border-radius: 20px;
  align-items: center;
  margin: unset;
  //padding: 0px 0px 20px 0px;
}
}
.threatcard-actionrow {
display: flex;
height: 80px;
padding: 0px 0px 0px 32px;
align-items: flex-start;
align-self: stretch;
}
.threatcard-actiontitle {
display: flex;
width: 207.5px;
padding: 32px 0px 10px 0px;
align-items: flex-start;
flex: 1 0 0;
text-align: left;
color: var(--header-on-white, #2F364B);
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}
.threatcard-actionimage {
  width: 87px;
  //height: 88px;
  height: auto;
  margin: 0px;
}
.threatcard-actiondescription {
display: flex;
white-space: pre-wrap;
align-items: flex-start;
gap: 10px;
align-self: stretch;
padding: 0px 32px 30px 32px;

flex: 1 0 0;
color: var(--messaging-on-white, #545E75);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;
}
@media (min-width: 840px) {
.threatcard-actiondescription {
display: flex;
max-width: 590px;
padding: 0px 32px 30px 32px;
align-items: flex-start;
gap: 10px;
align-self: stretch;

flex: 1 0 0;
color: var(--messaging-on-white, #545E75);
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
text-align: left;
}
}


.threatcard-button {
display: flex;
padding: 17px 25px 17px 25px;
margin: 0px 23px 30px 23px;
justify-content: center;
align-items: flex-start;
gap: 10px;
align-self: stretch;
text-decoration: none;
border-radius: 14px;
background: #FFF;
box-shadow: 0px 7px 11px 0px rgba(65, 72, 96, 0.08);
//border: none;

color: var(--Header-on-white, #2F364B); 
text-align: center;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 21px;
}
@media (min-width: 390px) {
.threatcard-button {
display: flex;
padding: 17px 25px 17px 25px;
margin: 0px 23px 30px 23px;
justify-content: center;
align-items: flex-start;
gap: 10px;
align-self: stretch;
text-decoration: none;
border-radius: 14px;
background: #FFF;
box-shadow: 0px 7px 11px 0px rgba(65, 72, 96, 0.08);
//border: none;

white-space: nowrap;
color: var(--Header-on-white, #2F364B); 
text-align: center;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: 21px;
}
}
//@media (min-width: 840px) {
//.threatcard-button {
//display: flex;
//padding: 15px 40px 12px 40px;
//justify-content: center;
//align-items: flex-start;
//gap: 10px;
//align-self: stretch;
//border-radius: 15px;
//background: linear-gradient(0deg, #231C3F 0%, #231C3F 100%), linear-gradient(0deg, #535256 0%, #535256 100%), #505050;
//box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
////border: none;
//text-decoration: none;
//
//white-space: nowrap;
//color: #FFFFFF;
//text-align: center;
//font-family: Gilroy;
//font-size: 17px;
//font-style: normal;
//font-weight: 700;
//line-height: 1;
//}
//}

.threatcard-button-container {
display: flex;
padding: 20px 33px 24px 33px;
flex-direction: column;
align-items: center;
gap: 5px;
align-self: stretch;
}
@media (min-width: 840px) {
.threatcard-button-container {
display: flex;
padding: 20px 0px 0px 0px;
flex-direction: column;
align-items: center;
gap: 5px;
align-self: stretch;
}
}

.threatcard-button-dismiss {
display: flex;
padding: 18px 40px 16px 40px;
justify-content: center;
align-items: flex-start;
gap: 10px;
min-width: 294px;
width: 100%;
border-radius: 15px;
background: linear-gradient(0deg, #231C3F 0%, #231C3F 100%), linear-gradient(0deg, #535256 0%, #535256 100%), #505050;
//box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
border: none;
text-decoration: none;

white-space: nowrap;

}
@media (min-width: 840px) {
.threatcard-button-dismiss {
display: flex;
padding: 18px 40px 16px 40px;
justify-content: center;
align-items: flex-start;
gap: 10px;
min-width: 526px;
width: unset;
border-radius: 15px;
background: linear-gradient(0deg, #231C3F 0%, #231C3F 100%), linear-gradient(0deg, #535256 0%, #535256 100%), #505050;
//box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
border: none;
text-decoration: none;

white-space: nowrap;
}
}

.threatcard-button-dismiss-text {
  line-height: 1;
  color: #FFFFFF;
  text-align: center;
  font-family: Gilroy;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 1;
}
