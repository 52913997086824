button {
    cursor: pointer;
}

div {
font-style: normal;
}

.slide-in {
    animation: slideIn .65s ease-out;
}

@keyframes slideIn {
    from {
        top: -400px;
    }
    to {
        top: 0;
    }
}

.slide-up {
    animation: slideUp .65s ease-out;
}

@keyframes slideUp {
    from {
        bottom: -1040px;
    }
    to {
        bottom: 0;
    }
}

.home {
  display: flex;
  background-color: #231C3F;
  background: #231C3F;
  width: 100vw;
  //height: calc(100% - 120px);
}

.main-container {
  background: #231C3F;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  min-width: 360px;
  width: 100%;
  position: relative;
}

@media (min-width: 501px) {
.main-container {
  background: #231C3F;
  min-height: calc(100vh);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  min-width: 500px;
  
  //position: absolute;
  position: relative;
  //height: 1034px;
}
}

@media (min-width: 840px) {
.main-container {
  background-color: #231C3F;
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 65px;
  
  //position: absolute;
  position: relative;
  width: 785px;
  //height: 1034px;
  left: calc(50% - 785px/2 + 0.5px);
}
}

.feed-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 0 10px;
width: 100%;
}
@media (min-width: 501px) {
.feed-container {
display: flex;
flex-direction: column;
align-items: center;
padding: unset;
}
}
@media (min-width: 840px) {
.feed-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 0px 0px 40px;
width: 500px;
}
}

.feed-header {
display: flex;
position: relative;
flex-direction: column;
align-items: center;
align-self: stretch;
padding: 30px 0px 45px;
//height: 343px;
}
@media (min-width: 840px) {
.feed-header {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 30px 0px 45px;
width: 500px;
//height: 343px;
}
}

.home-inner-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 0px;

width: 100%;
//height: 268px;
}
@media (min-width: 840px) {
.home-inner-container {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;

width: 500px;
//height: 268px;
}
}

.top-container {
display: flex;
justify-content: flex-end;
width: 100%;
height: 85px;
position: relative;
}
@media (min-width: 840px) {
.top-container {
display: flex;
justify-content: flex-end;
width: 500px;
height: 85px;
position: relative;
}
}

.home-kebab {
//position: absolute;
//right: 30px;
margin-left: auto;
margin-right: 20px;
}
@media (min-width: 501px) {
.home-kebab {
//position: absolute;
//right: 30px;
margin-left: auto;
margin-right: 30px;
}
}

.icon-wrapper {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
width: 70px;
height: 70px;
left: calc(50% - 70px/2);
//background: linear-gradient(0deg, #FF5154 0%, #FF5154 100%);
background: #FF5154;
box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.08);
border-radius: 9999px;
}
.icon-wrapper-safe {
position: absolute;
display: flex;
align-items: center;
justify-content: center;
width: 70px;
height: 70px;
left: calc(50% - 70px/2);
//background: linear-gradient(0deg, #FF5154 0%, #FF5154 100%);
background: #00E8AA;
box-shadow: 0px 7px 7px rgba(0, 0, 0, 0.08);
border-radius: 9999px;
}
//.icon {
//position: absolute;
//width: 9px;
//height: 33px;
//left: calc(50% - 85px/2);
////top: 30px;
//}
//@media (min-width: 840px) {
.icon {
width: 9px;
height: 33px;
}
.icon-safe {
width: 34px;
height: 32px;
}
//}

.title {
width: 100%;
height: 38px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 34px;
line-height: 38px;
/* identical to box height, or 112% */
text-align: center;
color: #FFFFFF;
}
@media (min-width: 501px) {
.title {
width: 500px;
height: 38px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 600;
font-size: 34px;
line-height: 38px;
/* identical to box height, or 112% */
text-align: center;
color: #FFFFFF;
}
}

.threat-status-detail {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 15px 30px 0px;
width: 100%;
//height: 145px;
}
@media (min-width: 501px) {
.threat-status-detail {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 15px 0px 0px;
width: 500px;
//height: 145px;
}
}

.threat-status-detail-inner-container {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 15px 20px;
width: 100%;
//height: 130px;
background: rgba(255, 255, 255, 0.1);
border-radius: 20px;
flex: none;
order: 0;
flex-grow: 1;
}
@media (min-width: 501px) {
.threat-status-detail-inner-container {
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
padding: 15px 20px;
width: 500px;
//height: 130px;
background: rgba(255, 255, 255, 0.1);
border-radius: 20px;
flex: none;
order: 0;
flex-grow: 1;
}
}

.threat-status-detail-inner-container-title {
white-space: pre-line;
width: 460px;
height: 100px;

font-family: 'Gilroy';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 25px;
text-align: center;

color: #FFFFFF;

flex: none;
order: 0;
flex-grow: 1;
}

.feed-container-cards {
display: flex;
position: relative;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 20px;
width: 100%;
}
@media (min-width: 501px) {
.feed-container-cards {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 20px;
width: 500px;
}
}

.breaches-card {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
width: 100%;
background: rgba(255, 255, 255, 0.06);
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
border-radius: 20px;
}
@media (min-width: 501px) {
.breaches-card {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
width: 500px;
background: rgba(255, 255, 255, 0.06);
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
border-radius: 20px;
}
}

.breaches-card-header-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 30px 16px;
gap: 20px;
width: 100%;
}
@media (min-width: 501px) {
.breaches-card-header-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 30px 16px;
gap: 20px;
width: 500px;
}
}

.breaches-card-header-icon {
width: 46px;
height: 50px;
}



.breaches-card-header {
//width: 210px;
//height: 25px;

text-align: center;
font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 25px;

/* identical to box height, or 114% */
display: flex;
align-items: center;

/* Header on dark */
color: #ECECEC;
}

.breaches-card-description-container {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px 25px 20px;
width: 100%;
}
@media (min-width: 501px) {
.breaches-card-description-container {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px 25px 20px;
width: 500px;
}
}

.breaches-card-description {
width: 450px;
//height: 72px;

font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;

/* or 133% */
text-align: center;

/* Message on dark */
color: #ECECEC;
}

.breaches-card-button-container {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 10px 20px 20px;
gap: 6px;
width: 100%;
height: 78px;
}
@media (min-width: 501px) {
.breaches-card-button-container {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 10px 20px 20px;
gap: 6px;
width: 500px;
height: 78px;
}
}

.breaches-card-button {
width: 100%;
height: 48px;
background: #FBFBFB;
border: 1px solid #FAFAFA;
box-shadow: 0px 0px 10px rgba(65, 72, 96, 0.12);
border-radius: 15px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 21px;
/* identical to box height */
text-align: center;
color: #2E364A;
}
@media (min-width: 501px) {
.breaches-card-button {
width: 460px;
height: 48px;
background: #FBFBFB;
border: 1px solid #FAFAFA;
box-shadow: 0px 0px 10px rgba(65, 72, 96, 0.12);
border-radius: 15px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 21px;
/* identical to box height */
text-align: center;
color: #2E364A;
}
}
.email-card {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
width: 100%;
height: 330px;
background: rgba(255, 255, 255, 0.06);
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
border-radius: 20px;
}
@media (min-width: 501px) {
.email-card {
display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
width: 500px;
height: 330px;
background: rgba(255, 255, 255, 0.06);
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
border-radius: 20px;
}
}
.email-card-header-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 30px 16px;
gap: 20px;
width: 100%;
height: 118px;
}
@media (min-width: 501px) {
.email-card-header-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 30px 16px;
gap: 20px;
width: 500px;
height: 118px;
}
}

.email-card-header-icon {
width: 52px;
height: 37px;
}

.email-card-header {
width: 323px;
height: 25px;

justify-content: center;
font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 22px;
line-height: 25px;

/* identical to box height, or 114% */
display: flex;
align-items: center;

color: #ECECEC;
}
.email-card-description-container {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px 25px 20px;
width: 100%;
height: 92px;
}
@media (min-width: 501px) {
.email-card-description-container {
display: flex;
flex-direction: row;
align-items: flex-start;
padding: 0px 25px 20px;
width: 500px;
height: 92px;
}
}
.email-card-description {
width: 100%;
height: 72px;

font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/* or 133% */
text-align: center;
color: #ECECEC;
}
@media (min-width: 501px) {
.email-card-description {
width: 450px;
height: 72px;

font-family: 'Gilroy';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
/* or 133% */
text-align: center;
color: #ECECEC;
}
}
.email-card-button-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 10px 20px 8px;
gap: 6px;
width: 100%;
height: 120px;
}
@media (min-width: 501px) {
.email-card-button-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 10px 20px 8px;
gap: 6px;
width: 500px;
height: 120px;
}
}
.email-card-button {
width: 100%;
height: 48px;
background: #FBFBFB;
border: 1px solid #FAFAFA;
box-shadow: 0px 0px 10px rgba(65, 72, 96, 0.12);
border-radius: 15px;

font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 1;
/* identical to box height */
text-align: center;
color: #2E364A;
}
@media (min-width: 501px) {
.email-card-button {
width: 460px;
height: 48px;
background: #FBFBFB;
border: 1px solid #FAFAFA;
box-shadow: 0px 0px 10px rgba(65, 72, 96, 0.12);
border-radius: 15px;

font-family: 'Gilroy';
font-style: normal;
font-weight: 700;
font-size: 17px;
line-height: 1;
/* identical to box height */
text-align: center;
color: #2E364A;
}
}
.email-card-dismiss {
//width: 100%;
height: 48px;
border: 0px;
background-color: transparent;
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
border-radius: 15px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 1;
/* identical to box height */
text-align: center;
text-decoration-line: underline;
color: #FFFFFF;
}
@media (min-width: 501px) {
.email-card-dismiss {
//width: 460px;
height: 48px;
border: 0px;
background-color: transparent;
box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.12);
border-radius: 15px;
font-family: 'Gilroy';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 1;
/* identical to box height */
text-align: center;
text-decoration-line: underline;
color: #FFFFFF;
}
}
.sidebar-section {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 20px;
cursor: pointer;
width: 220px;
height: 480px;
}

.sidebar-container {
display: flex;
position: static;
left: calc(100vw/2 + 165.5px);
width: 220px;
padding-top: 30px;
flex-direction: column;
align-items: flex-start;
border-radius: 20px;
}

.sidebar-header-container {
display: flex;
padding-bottom: 30px;
justify-content: center;
align-items: flex-start;
align-self: stretch;
}

.sidebar-header {
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.sidebar-header-status {
display: flex;
padding: 8px 14px 6px 14px;
align-items: flex-start;

border-radius: 20px;
background: linear-gradient(90deg, #FF543B 0%, #FF950A 100%);

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.sidebar-header-status-free {
display: flex;
flex-direction: row;
padding: 8px 14px 6px 14px;
//height: 41px;
align-items: center;
justify-content: center;
border-radius: 20px;
background: var(--Dark-Web-Monitoring, linear-gradient(0deg, #09FFFF 0%, #09FFFF 100%), #F9F6F4);

color: #2E364A;
text-align: center;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.sidebar-items-container {
display: flex;
padding-bottom: 20px;
flex-direction: column;
align-items: flex-start;
gap: 15px;
align-self: stretch;
}

.sidebar-row {
display: flex;
position: relative;
width: 220px;
height: 79px;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
}

.sidebar-left {
position: absolute;
left: 0;
display: flex;
width: 118px;
flex-direction: column;
align-items: flex-start;
}

.sidebar-image {
width: 118px;
height: 42px;
}

.sidebar-right {
position: absolute;
right: 0;
display: flex;
width: 118px;
flex-direction: column;
align-items: flex-start;
}

.sidebar-description {
display: flex;
flex-direction: column;
padding-top: 1px;
align-items: center;
align-self: stretch;

flex: 1 0 0;
color: #A8A8B8;
text-align: center;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.sidebar-description-override-white {
color: #FFF;
}

.sidebar-description-paid {
  color: #FFF;
}
.sidebar-footer-container {
display: flex;
padding-top: 16px;
position: relative;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
}

.sidebar-footer {
display: flex;
padding: 26px 15px 15px 15px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 15px;
background: rgba(255, 255, 255, 0.10);
}

.sidebar-checkmark {
position: absolute;
top: 0;
left: calc(50% - 191px/2);
}

.sidebar-footer-text {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: 1;
}

.sidebar-footer-text-mirkat {
text-align: center;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 600;
line-height: normal;
background: linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

.sidebar-footer-text-bottom {
color: #FFF;
font-family: Gilroy;
font-size: 15px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.sidebar-footer-free {
display: flex;
flex-direction: row;
align-items: center;
padding: 16px 20px 17px 20px;
align-self: stretch;
border-radius: 15px;
//border: 1px solid rgba(255, 255, 255, 0.10);
background: var(--mirkat-theme, linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%));

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: 1;
}


.home-popover {
position: fixed;
display: flex;
flex-direction: column;
align-items: center;
width: 100vw;
//height: 100vh;
height: 100vh;
overflow-y: hidden;
top: 0px;
left: 0px;
z-index: 99;
background: transparent;
}
@media (min-width: 840px) {
.home-popover {
position: fixed;
padding-top: 60px;
display: flex;
//overflow-y: unset;
overflow-y: auto;
flex-direction: column;
align-items: center;
width: 100%;
height: 100%;
top: 0px;
z-index: 99;
background: rgba(35, 28, 63, 0.5);
}
}

.home-upgrade {
position: fixed;
overflow-y: hidden;
display: flex;
flex-direction: column;
align-items: center;
z-index: 99;
top: 0%;
left: 0;
//background: rgba(35, 28, 63, 0.50);
width: 100%;
height: 100%;
}
@media (min-width: 840x) {
.home-upgrade {
position: fixed;
overflow-y: auto;
display: flex;
flex-direction: column;
align-items: center;
z-index: 99;
padding-top: unset;
top: 0%;
left: 0;
background: rgba(35, 28, 63, 0.50);
width: 100%;
height: 100vh;
}
}
@media (min-width: 840px) {
.home-upgrade {
position: fixed;
display: flex;
flex-direction: column;
align-items: center;
z-index: 99;
padding-top: 60px;
top: 0%;
left: 0;
background: rgba(35, 28, 63, 0.50);
width: 100%;
height: 100vh;
overflow-y: auto;
}
}

.upgrade-card {
display: flex;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-radius: 20px;
background: rgba(255, 255, 255, 0.06);
box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}

.upgrade-card-img {
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
border-radius: 20px 20px 0px 0px;
}

.upgrade-card-header-container {
display: flex;
padding: 20px 30px 16px 30px;
flex-direction: column;
align-items: center;
gap: 8px;
align-self: stretch;
}

.upgrade-card-header {
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 25px; /* 113.636% */
}

.upgrade-card-span {
background: linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%);
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: 25px;
}

.upgrade-card-description {
display: flex;
padding: 0px 25px 20px 25px;
align-items: flex-start;
align-self: stretch;

flex: 1 0 0;
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: 24px; /* 133.333% */
}

.upgrade-card-buttons-container {
display: flex;
padding: 10px 20px 8px 20px;
flex-direction: column;
align-items: center;
gap: 6px;
align-self: stretch;
}

.upgrade-card-button {
display: flex;
flex-direction: row;
cursor: pointer;
padding: 12px 40px 14px 40px;
//padding: 0px 0px 2px 0px;
height: 48px;
justify-content: center;
align-items: center;
gap: 10px;
//flex: 1 0 0;
align-self: stretch;
border-radius: 15px;
border: 1px solid #FAFAFA;
background: #FBFBFB;
box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.12);

color: #2E364A;
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 700;
line-height: 1.0;
}

.upgrade-card-dismiss {
display: flex;
cursor: pointer;
padding: 15px 0px 12px 0px;
justify-content: center;
align-items: flex-start;
gap: 10px;
flex: 1 0 0;
border-radius: 15px;

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
}

.bbar-container {
display: flex;
//display: none;
//position: absolute;
flex-direction: column;
background: #322853;
align-self: stretch;
}

.bbar-top {
display: flex;
padding-top: 20px;
flex-direction: column;
align-items: center;
align-self: stretch;
background: linear-gradient(180deg, #231C3F 0%, #322855 100%);
}

.bbar-illustration-container {
width: 316px;
height: 171px;
}
.bbar-illustration {
width: 316.279px;
height: 203.802px;
}

.bbar-header {
display: flex;
padding: 10px 30px 35px 30px;
justify-content: center;
align-items: flex-start;
align-self: stretch;
}

.bbar-header-text {
display: flex;
align-items: flex-start;
gap: 10px;
}

.bbar-header-text-left {
display: flex;
width: 94px;
flex-direction: column;
justify-content: center;
align-self: stretch;

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.bbar-header-text-right {
display: flex;
padding: 8px 14px 6px 14px;
align-items: flex-start;
border-radius: 20px;
background: var(--Dark-Web-Monitoring, linear-gradient(0deg, #09FFFF 0%, #09FFFF 100%), #F9F6F4);

color: #2E364A;
text-align: center;
font-family: Gilroy;
font-size: 22px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.bbar-items-container {
display: flex;
padding: 0px 30px 30px 30px;
flex-direction: column;
align-items: center;
gap: 15px;
align-self: stretch;
}

.bbar-items {
display: flex;
max-width: 500px;
width: 100%;
flex-direction: column;
align-items: center;
gap: 15px;
}

.bbar-items-row {
display: flex;
width: 100%;
max-width: 500px;
justify-content: space-between;
align-items: flex-start;
}

.bbar-item-container {
display: flex;
width: 100px;
flex-direction: column;
align-items: flex-start;
//gap: 6px;
}

.bbar-item {
height: 54px;
align-self: stretch;
}

.bbar-item-header {
display: flex;
padding-top: 1px;
align-items: flex-start;
align-self: stretch;

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}

.bbar-button-container {
display: flex;
padding: 0px 30px 44px 30px;
flex-direction: column;
align-items: center;
gap: 25px;
align-self: stretch;
}

.bbar-button {
display: flex;
justify-content: center;
padding: 18px 14px 15px 14px;
max-width: 500px;
width: 100%;
border-radius: 15px;
background: var(--mirkat-theme, linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%));

color: #FFF;
font-family: Gilroy;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.bbar-indicator-container {
display: flex;
padding: 21px 0px 8px 0px; 
height: 34px;
justify-content: center;
align-items: center;
align-self: stretch;
background: #312853;
}

.bbar-indicator {
width: 134px;
height: 5px;
flex-shrink: 0;
border-radius: 100px;
background: #FFF;
}


section.monitoring .container {
  //padding-top: 20px;
  //padding-bottom: 20px;
}
  section.monitoring .visual {
    position:relative;
    //padding-bottom: 20px;
  }
  $particlesWidth: 316;
  $particlesHeight: 203; 
  section.monitoring .visual .particles {
    position: relative;
    width: #{$particlesWidth}px;
    height: #{$particlesHeight}px;
    margin: 0 auto;
    /*border: 1px solid red;*/
  }
    section.monitoring .visual .particles .particle {
      position: absolute;
      border-radius: 50%;
    }
    @for $i from 1 through 120 {
      @keyframes particle-animation-#{$i} {
        100% {
          transform: translate3d((random($particlesWidth) * 1px), (random($particlesHeight) * 1px), (random(100) * 1px));
        }
      }
      
      .particle:nth-child(#{$i}){
        animation: particle-animation-#{$i} 30s infinite;
        $size: random(3) + px;
        opacity: random(80)/100;
        height: $size;
        width: $size;
        animation-delay: -$i * .2s;
        transform: translate3d((random($particlesWidth) * 1px), (random($particlesHeight) * 1px), (random(100) * 1px));
        background: #09ffff;
      }
    }

  section.monitoring .visual .spinner {
    position:absolute;
    width: 110px;
    height: 110px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    animation: rotating 40s linear infinite;
  }
    section.monitoring .visual .spinner img {
      width: 100%;
      height: 100%;
    }

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

