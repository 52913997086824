.dayssince-date {
  display: flex;
  padding: 20px 30px 10px;
  align-items: flex-start;
  justify-content: center;
  gap: 10px;
  align-self: stretch;

  color: #545E75;
  text-align: center;
  font-size: 17px;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
}
