.page {
display: flex;
justify-content: center;
align-items: start;
}

.outer-container {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
height: 100%;
padding-bottom: 30px;
}
@media (min-width: 840px) {
.outer-container {
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
height: 100%;
padding-top: 50px;
padding-bottom: 50px;
}
}
.inner-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 20px 20px 0px 20px; 
width: 100%;
max-width: 550px;
min-width: 360px;
}
@media (min-width: 840px) {
.inner-container {
display: flex;
flex-direction: column;
align-items: center;
padding: 35px;
width: 100%;
max-width: 550px;
min-width: 360px;

border-radius: 20px;
background: rgba(255, 255, 255, 0.06);
box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.12);
}
}
.header-container {
display: flex;
padding: 0px 10px 30px 10px; 
flex-direction: column;
align-items: center;
gap: 15px;
}
@media (min-width: 840px) {
.header-container {
display: flex;
padding: 0px 10px 30px 10px;
flex-direction: column;
align-items: center;
gap: 15px;
align-self: stretch;
}
}
.header-one {
display: flex;
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 28px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.header-two {
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.button-free-container {
display: flex;
padding-bottom: 30px;
flex-direction: column;
align-items: center;
gap: 15px;
}
.button-free {
display: flex;
gap: 10px;
justify-content: center;
align-items: center;

color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-decoration-line: underline;
}

.paid-button-container {
display: flex;
padding-bottom: 30px;
flex-direction: column;
align-items: center;
gap: 15px;
align-self: stretch;
}

.paid-button {
display: flex;
height: 60px;
//padding: 15px 40px 12px 40px;
justify-content: center;
align-items: center;
gap: 10px;
align-self: stretch;
border-radius: 15px;
background: var(--mirkat-theme, linear-gradient(90deg, #FF3F29 0%, #FF8E00 100%));
box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
text-decoration: none;
}
.paid-button-text {
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 1.25;
}

.button-description {
color: #FFF;
text-align: center;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}


.comparison-container {
display: flex;
flex-direction: column;
width: 100%;
padding: 0px 10px;
justify-content: center;
align-items: center;
}
.comparison-header {
height: 80px;
gap: 1px;
display: flex;
align-self: stretch;
border-bottom: 1px solid #453F5E;
}
.comparison-header-edge-item {
display: flex;
//width: 142px;
//flex: 0 0 calc(41% - 2px);
flex: 1;
height: 80px;
padding: 0px 0px 8px 10px;
align-items: flex-end;
flex: 1 0 0;

color: #FFF;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.comparison-header-item {
display: flex;
//width: 105px;
flex: 0 0 calc(30%);
height: 80px;
justify-content: center;
align-items: center;
flex-shrink: 0;

color: #FFF;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.comparison-header-end-item {
display: flex;
//width: 105px;
flex: 0 0 calc(30% - 1px);
height: 80px;
justify-content: center;
align-items: center;
flex-shrink: 0;

background: rgba(255, 255, 255, 0.05);

color: #FFF;
font-family: Gilroy;
font-size: 17px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.row {
display: flex;
height: 60px;
justify-content: center;
align-items: center;
gap: 1px;
width: 100%;

//border-top: 1px solid #453F5E;
border-bottom: 1px solid #453F5E;
}

.row-edge {
display: flex;
//flex: 0 0 calc(41% - 2px);
flex: 1;
padding-left: 10px;
padding-top: 1px;
align-items: center;
gap: 10px;
flex: 1 0 0;

color: #FFF;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
.row-edge-wrap {
display: flex;
//flex: 0 0 calc(41% - 2px);
flex: 1;
padding-left: 10px;
padding-top: 1px;
align-items: center;
gap: 10px;
flex: 1 0 0;

color: #FFF;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
@media (min-width: 391px) {
.row-edge-wrap {
display: flex;
white-space: nowrap;
//flex: 0 0 calc(41% - 2px);
flex: 1;
padding-left: 10px;
padding-top: 1px;
align-items: center;
gap: 10px;
flex: 1 0 0;

color: #FFF;
font-family: Gilroy;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: normal;
}
}

.row-free {
display: flex;
flex: 0 0 calc(30%);
justify-content: center;
align-items: center;
gap: 10px;
}

.row-end {
display: flex;
flex: 0 0 calc(30% - 1px);
height: 60px;
justify-content: center;
align-items: center;
gap: 10px;

background: rgba(255, 255, 255, 0.05);
}
