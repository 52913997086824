

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: rgba(35, 28, 63, 0.5);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  z-index: 999999;
  width: 100%;
  height: 100%;
  overflow: auto;
}


.m-container {
  display: flex;
  padding: 20px 15px 15px 15px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 15px;
  background: #FFF;
  box-shadow: 0px 0px 30px 0px #272041;
  max-width: 440px;

}
.m-content {
  display: flex;
  padding: 0px 10px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;;
}

.m-header-text {
  color: #1B122C;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.m-content-text {
  color: #5A6A7A;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.m-action-container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}



.red-cancel-button {
  display: flex;
  padding: 15px 30px 12px 30px;
  align-items: flex-start;
  gap: 10px;
  background-color: #CB0000;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 0px rgba(65, 72, 96, 0.15);
  cursor: pointer;
}

.red-cancel-button-text {
  color: #FFF;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.white-keep-button-container {
  display: flex;
  align-items: flex-start;
  cursor: pointer;
}

.white-keep-button {
  display: flex;
  align-items: flex-start;
}

.white-keep-button-primary-default {
  display: flex;
  padding: 15px 30px 12px 30px;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 15px;
}